import "./App.css";
import Header from "./containers/header/Header";
import Footer from "./containers/footer/Footer";
import Menu from "./containers/menu/Menu";
import Company from "./containers/company/components/Company";
import CompanyDetails from "./containers/company/components/CompanyDetails";
import CompanyEdit from "./containers/company/components/CompanyEdit";

import Division from "./containers/division/components/Division";
import DivisionDetails from "./containers/division/components/DivisionDetails";
import DivisionEdit from "./containers/division/components/DivisionEdit";

import Market from "./containers/market/components/Market";
import MarketTracker from "./containers/market/components/MarketTracker";
import MarketDetails from "./containers/market/components/MarketDetails";
import MarketEdit from "./containers/market/components/MarketEdit";

import Region from "./containers/region/components/Region";
import RegionDetails from "./containers/region/components/RegionDetails";
import RegionEdit from "./containers/region/components/RegionEdit";
import RegionTracker from "./containers/region/components/RegionTracker";

import Stores from "./containers/stores/components/Stores";
import StoreTracker from "./containers/stores/components/StoreTracker";
import StoresAdd from "./containers/stores/components/StoresAdd";
import StoreDetails from "./containers/stores/components/StoreDetails";
import Employees from "./containers/employees/components/Employee";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Store from "./redux/Store";
import { Provider } from "react-redux";
import { useState } from "react";
import "./assets/css/icons.css";

import Acquisition from "./containers/acquisition/components/Acquisition";
import Density from "./containers/density/components/Density";
import Classficaton from "./containers/store-classification/components/Classification";
import CommisionCompGroup from "./containers/commission-comp-group/components/CommisionCompGroup";
import ChannelType from "./containers/storeChannelType/components/ChannelType";
import StoresEdit from "./containers/stores/components/StoresEdit";
import Dashboard from "./containers/home/components/Dashboard";
import StoreHours from "./containers/store-hours/components/StoreHours";
import Department from "./containers/employee_departments/components/Department";
import LeaveType from "./containers/employee_leave_type/components/LeaveType.js";
import EmployeeType from "./containers/employee_type/components/EmployeeType";
import JobTitles from "./containers/jobtitles/components/JobTitles";
import JobTitleDetails from "./containers/jobtitles/components/JobTitleDetails";
import JobTitlesEdit from "./containers/jobtitles/components/JobTitlesEdit";
import EmployeeStatus from "./containers/employee_status/components/EmployeeStatus";
import EmployeeDetails from "./containers/employees/components/EmployeeDetails";
import EmployeeAdd from "./containers/employees/components/EmployeeAdd";
import EmployeeEdit from "./containers/employees/components/EmployeeEdit";
import HireStatus from "./containers/hire-status/components/HireStatus";
import TermReason from "./containers/term-reason/components/TermReason";
import FullPartTime from "./containers/full_part_time_status/components/FullPartTime";
import HourlySalary from "./containers/hourly_salary_status/components/HourlySalary";
import DensityClassTracker from "./containers/stores/components/DensityClassTracker";
import CommCompTracker from "./containers/stores/components/CommCompTracker";
import StoreClassificationTrackerGetAll from "./containers/stores/components/StoreClassificationTrackerGetAll";
import DesignationTrackerGetAll from "./containers/employees/components/DesignationTrackerGetAll";
import EmpTypeHireStatusTrackerGetAll from "./containers/employees/components/EmpTypeHireStatusTrackerGetAll";
import Login from "./containers/login/components/Login";
import Logout from "./containers/login/components/Logout";
import MainLayout from "./layouts/MainLayout";
import Roles from "./containers/roles/components/Roles";
import Groups from "./containers/groups/components/Groups";
import ModuleMetaData from "./containers/module_meta_data/components/ModuleMetaData";
import RoleModuleMapping from "./containers/role_module_mapping/components/RoleModuleMapping";
import EmployeeRoleMapping from "./containers/employee_role_mapping/components/EmployeeRoleMapping";
import { RoleModuleMappingCreatePage } from "./containers/role_module_mapping/components/RoleModuleMappingCreatePage";
import { RoleModuleMappingEditPage } from "./containers/role_module_mapping/components/RoleModuleMappingEditPage";
import { OdooStoreTransferTrackers } from "./containers/odoo_reference/OdooStoreTransferTracker/components/OdooStoreTransferTracker";
import OdooStoreTransferTrackerDetails from "./containers/odoo_reference/OdooStoreTransferTracker/components/OdooStoreTransferTrackerDetails";
import { OdooDesignationTrackers } from "./containers/odoo_reference/OdooDesignationTracker/components/OdooDesignationTracker";
import OdooDesignationTrackerDetails from "./containers/odoo_reference/OdooDesignationTracker/components/OdooDesignationTrackerDetails";
import KeyTag from "./containers/tagging/components/KeyTag";
import { SiteAlerts } from "./containers/alert/SiteAlerts";
import QueueTable from './containers/queue_table/components/QueueTable';
import QuarterlyReview from './containers/quarterlyReview/components/QuarterlyReview';
import Audits from "./containers/auditLog/components/Audits";
import { OdooTrackersByEmp } from "./containers/odoo_reference/OdooDesignationTracker/components/OdooTrackersByEmpId";
import { HireTermQueue } from "./containers/hireTermQueue/components/HireTermQueue";
import RequestPasswordReset from "./containers/login/components/RequestPasswordReset";
import CreateNewPassword from "./containers/login/components/CreateNewPassword";
import Login2 from "./containers/login/components/Login2";
import SalesUsers from "./containers/salesUsers/components/SalesUsers";
import UserProfile from "./containers/UserProfile/UserProfile";
import GetAllTShirtSize from "./containers/salesUsers/components/GetAllTShirtSize";
import Products from "./containers/productUI/products/components/Products";
import ProductType from "./containers/productUI/productType/components/ProductType";
import AccountType from "./containers/productUI/account_type/components/AccountType";
import Category from "./containers/productUI/category/components/Category";
import CategoryProduct from "./containers/productUI/category_product/components/CategoryProduct";
import Subcategory from "./containers/productUI/line_type/components/Subcategory";
import RelationshipType from "./containers/productUI/relationship_type/components/RelationshipType";
import ProductTables from "./containers/productUI/productTables/components/ProductTables";
import ServiceType from "./containers/productUI/service_type/components/ServiceType";
import RetailFoundation from "./containers/retail_foundation/components/RetailFoundation.js";
//import SearchSAP from "./containers/search-sap/components/SearchSAP.js";
//import DynamicFormBulkImport from "./containers/dynamic_form/components/DynamicFormBulkImport";

import MyPlan from "./containers/my_plan/components/MyPlans.js";
import MyPlanForm from "./containers/my_plan/components/MyPlanForm.js";
import TeamPriority from "./containers/my_plan/components/TeamPriority.js";
import AllPlan from "./containers/my_plan/components/AllPlan.js";

const App = () => {
  // let [sidebarToggled, setSidebarToggled] = useState(true);
  // let wrapperClass = sidebarToggled ? " toggled" : "";
  // function toggle() {
  //   setSidebarToggled(!sidebarToggled);
  // }

  return (
    <Provider store={Store}>
      <Router>
        <SiteAlerts />
        {/* <div className={"wrapper " + wrapperClass}>
          {sidebarToggled ? (
            <div>
              <MenuCollapsed />
            </div>
          ) : (
            <div className="menu">
              <Menu/>
            </div>
          )
          }

          <div className="main">
            <Header toggleMenu={toggle} /> */}
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* <Route path="/login2" element={<Login2 />} /> */}
          <Route path="/logout" element={<Logout />} />
          <Route path="/reset" element={<RequestPasswordReset />} />
          <Route path="/reset/:key/" element={<CreateNewPassword />} />
          <Route path="/reset1" element={<CreateNewPassword />} />
          <Route element={<MainLayout />}>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/company" element={<Company />} />
            <Route path="/company/:id" element={<CompanyDetails />} />
            <Route path="/company/edit/:id" element={<CompanyEdit />} />

            <Route path="/division" element={<Division />} />
            <Route path="/division/:id" element={<DivisionDetails />} />
            <Route path="/division/edit/:id" element={<DivisionEdit />} />

            <Route path="/region" element={<Region />} />
            <Route path="/region/:id" element={<RegionDetails />} />
            <Route path="/region/edit/:id" element={<RegionEdit />} />
            <Route path="/region-tracker" element={<RegionTracker />} />

            <Route path="/district" element={<Market />} />
            <Route path="/district/:id" element={<MarketDetails />} />
            <Route path="/district/edit/:id" element={<MarketEdit />} />
            <Route path="/district-tracker" element={<MarketTracker />} />

            <Route path="/acquisition" element={<Acquisition />} />

            <Route path="/density" element={<Density />} />

            <Route path="/store-classification" element={<Classficaton />} />

            <Route
              path="/commission-comp-group"
              element={<CommisionCompGroup />}
            />

            <Route path="/channel-type" element={<ChannelType />} />

            <Route path="/stores" element={<Stores />} />
            <Route path="/stores/add" element={<StoresAdd />} />
            <Route path="/stores/:id" element={<StoreDetails />} />
            <Route path="/stores/edit/:id" element={<StoresEdit />} />
            <Route path="/store-hours" element={<StoreHours />} />
            <Route path="/store-tracker" element={<StoreTracker />} />
            <Route
              path="/densityclass-tracker"
              element={<DensityClassTracker />}
            />
            <Route path="/commcomp-tracker" element={<CommCompTracker />} />
            <Route
              path="/store-classification-tracker"
              element={<StoreClassificationTrackerGetAll />}
            />

            <Route path="/employees" element={<Employees />} />
            <Route path="/employees/:id" element={<EmployeeDetails />} />
            <Route path="/employees/add" element={<EmployeeAdd />} />
            <Route path="/employees/edit/:id" element={<EmployeeEdit />} />
            <Route path="/departments" element={<Department />} />
            <Route path="/leave-types" element={<LeaveType />} />
            <Route path="/employee-types" element={<EmployeeType />} />
            <Route path="/employee-status" element={<EmployeeStatus />} />
            <Route path="/hire-status" element={<HireStatus />} />
            <Route path="/term-reason" element={<TermReason />} />
            <Route path="/jobtitles" element={<JobTitles />} />
            <Route path="/jobtitles/:id" element={<JobTitleDetails />} />
            <Route path="/jobtitles/edit/:id" element={<JobTitlesEdit />} />
            <Route path="/full-part-time" element={<FullPartTime />} />
            <Route path="/hourly-salary" element={<HourlySalary />} />
            <Route
              path="/designation-tracker"
              element={<DesignationTrackerGetAll />}
            />
            <Route
              path="/emptype-hirestatus-tracker"
              element={<EmpTypeHireStatusTrackerGetAll />}
            />
            <Route path="/roles" element={<Roles />} />
            <Route path="/groups" element={<Groups />} />
            <Route path="/module-meta-data" element={<ModuleMetaData />} />
            <Route
              path="/role-module-mapping"
              element={<RoleModuleMapping />}
            />
            <Route
              path="/employee-role-mapping"
              element={<EmployeeRoleMapping />}
            />
            <Route path="/role-module-mapping/create" element={<RoleModuleMappingCreatePage />} />
            <Route path="/role-module-mapping/edit/:id" element={<RoleModuleMappingEditPage />} />

            <Route path="/odoo-store-transfer-trackers" element={<OdooStoreTransferTrackers />} />
            <Route path="/odoo-store-transfer-trackers/:id" element={<OdooStoreTransferTrackerDetails />} />
            <Route path="/odoo-designation-trackers" element={<OdooDesignationTrackers />} />
            <Route path="/odoo-designation-trackers/:id" element={<OdooDesignationTrackerDetails />} />
            <Route path="/odoo-trackers/:id" element={<OdooTrackersByEmp />} />

            <Route path="/admin-key-tag" element={<KeyTag />} />

            <Route path='/queue-table' element={<QueueTable />} />
            <Route path="/audit" element={<Audits />} />
            <Route path="/hireTermQueue" element={<HireTermQueue />} />
            <Route path="/t-shirtSize" element={<SalesUsers />} />
            <Route path="/userProfile" element={<UserProfile />} />
            <Route path="/all-tshirtsizes" element={<GetAllTShirtSize />} />

            {/* Product Related pages */}
            <Route path="/products" element={<Products />} />
            <Route path="/product-tables" element={<ProductTables />} />
            <Route path="/product-type" element={<ProductType />} />
            <Route path="/service-type" element={<ServiceType />} />            
            <Route path="/account-type" element={<AccountType />} />
            <Route path="/category" element={<Category />} />
            <Route path="/category-product" element={<CategoryProduct />} />
            <Route path="/line-type" element={<Subcategory />} />
            <Route path="/relationship-type" element={<RelationshipType />} />
            
            <Route path="/quarterlyReview" element={<QuarterlyReview />} />
            <Route path="/retail-foundation" element={<RetailFoundation />} />
    	    {/* <Route path="/search-sap" element={<SearchSAP />} /> 

          <Route path="/form-bulk-import" element={<DynamicFormBulkImport />} /> */ }

            <Route path="*" element={<Navigate to="dashboard" />} />
            <Route path="/myplan/form/:id" element={<MyPlanForm />} />
            <Route path="/teamplan" element={<TeamPriority />} />
            <Route path="/myplan" element={<MyPlan />} />
            <Route path="/allplan" element={<AllPlan />} />
            
          </Route>
        </Routes>
        <Footer />
        {/* </div>
        </div> */}
      </Router>
    </Provider>
  );
};

export default App;
