import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import DefaultButton from "../../../../shared/components/DefaultButton";
import { CANCEL, SAVE } from "../../../../shared/constants/Constants";
import FormikSelectField from "../../../../shared/components/FormikSelectField";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { loadATypeDataRequest, loadLTypeDataRequest, loadPTDataRequest, loadPTypeDataRequest } from "../../productTables/redux/Actions";
import FormikDatePickerField from "../../../../shared/components/FormikDatePickerField";
import FormatDate from "../../../../shared/components/FormatDate";
import { useState } from "react";
import { loadCategoryDataRequest, loadSubCategoryDataRequest } from "../redux/Actions";

const ProductForm = ({ pData, submit, cancel, newProductType, productType }) => {
    const dispatch = useDispatch(); 
    let { productTables, pTypes, aTypes, lTypes } = useSelector((state) => state.productTables); 
    let { categories, subcategories } = useSelector((state) => state.products);
    useEffect(() => {
        dispatch(loadPTDataRequest())
    }, [dispatch])

    useEffect(() => {
        if (pTypes.length === 0) {
            dispatch(loadPTypeDataRequest());
        }
        if (aTypes.length === 0) {
            dispatch(loadATypeDataRequest());
        }
        if (lTypes.length === 0) {
            dispatch(loadLTypeDataRequest());
        }
        if (categories.length === 0) {
            dispatch(loadCategoryDataRequest());
        }
        // if (subcategories.length === 0) {
        //     dispatch(loadSubCategoryDataRequest()); 
        // }
    }, [dispatch])
    
    const validate = (values) => {
        const errors = {};
        if (!values.name) {
            errors.name = "Required";
        }
        if (!values.producttype_id) {
            errors.producttype_id = "Required";
        }
        if (!values.subcategory_id) {
            errors.subcategory_id = "Required";
        }
        if (!values.code) {
            errors.code = "Required";
        }
        if (productType === "accessory") {
            if (!values.start_date) {
                errors.start_date = "Required";
            }
            if (!values.end_date) {
                errors.end_date = "Required";
            }
            if (!values.msrp) {
                errors.msrp = "Required";
            }
            if (!values.commission) {
                errors.commission = "Required";
            }
        } else {
            if (!values.mrc) {
                errors.mrc = "Required";
            }
        }
        return errors;
    };

    const formik = useFormik({});
    useEffect(() => {
        if (pData) {
            formik.current.setFieldValue('id', pData?.id);
            formik.current.setFieldValue('name', pData?.name);
            formik.current.setFieldValue('description', pData?.description);
            formik.current.setFieldValue('producttype_id', pData?.producttype_id);
            formik.current.setFieldValue('account_type_id', pData?.account_type_id);
            formik.current.setFieldValue('category_id', pData?.category_id);
            formik.current.setFieldValue('subcategory_id', pData?.subcategory_id);
            formik.current.setFieldValue('commission', (pData?.commission === "NaN" || pData?.commission === 0  || pData?.commission === "0.00" || pData?.commission === "" || pData?.commission === null) ? 0 : pData?.commission);
            formik.current.setFieldValue('code', pData?.code);
            formik.current.setFieldValue('mrc', (pData?.mrc === "NaN" || pData?.mrc === 0 || pData?.mrc === "0.00" || pData?.mrc === "" || pData?.mrc === null) ? 0 : pData?.mrc);
            formik.current.setFieldValue('msrp', (pData?.msrp === "NaN" || pData?.msrp === 0 || pData?.msrp === "0.00" || pData?.msrp === "" || pData?.msrp === null) ? 0 : pData?.msrp);
            if (pData && pData.start_date && pData.end_date) {
                if (pData?.start_date.includes("-") && pData?.end_date.includes("-")) {
                    formik.current.setFieldValue('start_date', FormatDate(pData?.start_date)); 
                    formik.current.setFieldValue('end_date', FormatDate(pData?.end_date));
                }
            }
        }
    }, [pData, formik])

    const [productTypeData, setProductTypeData] = useState([]);
    const [accessoryTypeData, setAccessoryTypeData] = useState([]);
    useEffect(() => {
        if (pTypes) {
            setAccessoryTypeData([...pTypes].filter(
                (accessoryType) => {
                    return (accessoryType.name == "Accessory")
                }
            )) 
            setProductTypeData([...pTypes].filter(
                (productType) => {
                    return (productType.name == "Postpaid" || productType.name == "Prepaid" || productType.name == "Feature")
                }
            )) 
        }
    }, [pTypes])

    let [subCatList, setSubCatList] = useState([]);
    useEffect(() => {
        setSubCatList(lTypes)
    }, [lTypes])    

    return (
        <Formik
            innerRef={formik}
            initialValues={{
                name: '',
                description: '',
                producttype_id: "",
                account_type_id: "",
                category_id: "",
                subcategory_id: "",
                start_date: null,
                end_date: null,
                msrp: 0,
                commission: 0,
                code: null,
                mrc: 0,
            }}
            validate={validate}
            onSubmit={submit}
            enableReinitialize
        >
            {({ values, setFieldTouched, setFieldValue }) => (
                <Form className="row g-4">
                    <div className="col-md-6">
                        <label htmlFor="name" className="form-label">
                            {productType === "accessory" ? "Accessory" : "Product" } Name<span className="error-text">*</span>
                        </label>
                        <Field
                            className="form-control"
                            type="text"
                            name="name"
                            placeholder={productType === "accessory" ? "Enter Accessory Name" : "Enter Product Name" }
                            autoComplete="off"
                        />
                        <span className="error-text">
                            <ErrorMessage name="name" />
                        </span>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="description" className="form-label">
                            {productType === "accessory" ? "Accessory" : "Product" } Description
                        </label>
                        <Field
                            className="form-control"
                            type="text"
                            name="description"
                            placeholder={productType === "accessory" ? "Enter Accessory Description" : "Enter Product Description" }
                            autoComplete="off"
                        />
                    </div>
                    {productType === "accessory" && (<>
                        <div className="col-md-6">
                            <label htmlFor="name" className="form-label">
                                Product Type<span className="error-text">*</span>
                            </label>
                            <FormikSelectField
                                name="producttype_id"
                                placeholder={"Select Product Type"}
                                onChange={setFieldValue}
                                value={values.producttype_id}
                            >
                                {accessoryTypeData.map((pType) => {
                                    return <option key={pType.id} value={pType.id}>{pType.name}</option>
                                })}
                            </FormikSelectField>
                            <span className="error-text">
                                <ErrorMessage name="producttype_id" />
                            </span>
                        </div>
                    </>)}
                    {productType === "product" && (<>
                        <div className="col-md-6">
                            <label htmlFor="name" className="form-label">
                                Product Type<span className="error-text">*</span>
                            </label>
                            <FormikSelectField
                                name="producttype_id"
                                placeholder={"Select Product Type"}
                                onChange={setFieldValue}
                                value={values.producttype_id}
                            >
                                {productTypeData.map((pType) => {
                                    return <option key={pType.id} value={pType.id}>{pType.name}</option>
                                })}
                            </FormikSelectField>
                            <span className="error-text">
                                <ErrorMessage name="producttype_id" />
                            </span>
                        </div>
                    </>)}  
                    {productType === "product" && (<>                                    
                    <div className="col-md-6">
                        <label htmlFor="name" className="form-label">
                            Account Type
                        </label>
                        <FormikSelectField
                            name="account_type_id"
                            placeholder={"Select Account Type"}
                            onChange={setFieldValue}
                            value={values.account_type_id}
                        >
                            {aTypes.map((aType) => {
                                return <option key={aType.id} value={aType.id}>{aType.name}</option>
                            })}
                        </FormikSelectField>
                    </div>
                    </>)}
                    <div className="col-md-6">
                        <label htmlFor="name" className="form-label">
                            Category
                        </label>
                        <FormikSelectField
                            name="category_id"
                            value={values.category_id}
                            onChange={(name, value) => {
                                setFieldValue(name, value)
                                setSubCatList(
                                    [...lTypes].filter(
                                        (cat) => {
                                            return cat.category_id == value.toString()
                                        }
                                    )
                                )
                                let selectedDepartmentCompanyId = lTypes.find(
                                    cate => cate.id === values.subcategory_id
                                )?.category_id;
                                if (selectedDepartmentCompanyId !== value.toString()) {
                                    setFieldValue('subcategory_id', null)
                                }
                            }
                            }
                            placeHolder="Select Category"
                        >
                            {categories.map((company) => {
                                return <option value={company.id}>{company.name}</option>
                            })
                            }
                        </FormikSelectField>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="name" className="form-label">
                            Sub Category<span className="error-text">*</span>
                        </label>
                        <FormikSelectField
                            name="subcategory_id"
                            value={values.subcategory_id}
                            onChange={(name, value) => {
                                let category_id = lTypes.find(sCat => sCat.id === value).category_id
                                setFieldValue(name, value)
                                setFieldValue("category_id", category_id) 
                            }}
                            placeholder={"Select Subcategory"}
                        >
                            {subCatList.map((subCat) => {
                                return <option value={subCat.id} >{subCat.name}</option>
                            })
                            }
                        </FormikSelectField>
                        <span className="error-text">
                            <ErrorMessage name="subcategory_id" />
                        </span>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="code" className="form-label">
                            Code<span className="error-text">*</span>
                        </label>
                        <Field
                            className="form-control"
                            type="text"
                            name="code"
                            placeholder={"Enter Code"}
                            autoComplete="off"
                        />
                        <span className="error-text">
                            <ErrorMessage name="code" />
                        </span>
                    </div>
                    {productType === "accessory" && (<>
                        <div className="col-md-6 datepicker">
                            <label htmlFor="start_date" className="form-label">
                                Start Date<span className="error-text">*</span>
                            </label>
                            <FormikDatePickerField name="start_date" value={values.start_date} onChange={setFieldValue} handleBlur={setFieldTouched} placeholder={"Choose Start Date"} />
                            <span className="error-text">
                                <ErrorMessage name="start_date" />
                            </span>
                        </div>
                        <div className="col-md-6 datepicker">
                            <label htmlFor="end_date" className="form-label">
                                End Date<span className="error-text">*</span>
                            </label>
                            <FormikDatePickerField name="end_date" value={values.end_date} onChange={setFieldValue} handleBlur={setFieldTouched} placeholder={"Choose End Date"} />
                            <span className="error-text">
                                <ErrorMessage name="end_date" />
                            </span>
                        </div>
                    </>)}
                    {productType === "product" && (<>
                    <div className="col-md-6">
                        <label htmlFor="mrc" className="form-label">
                            MRC<span className="error-text">*</span>
                        </label>
                        <Field
                            className="form-control"
                            type="text"
                            name="mrc"
                            placeholder={"Enter MRC"}
                            autoComplete="off"
                        />
                        <span className="error-text">
                            <ErrorMessage name="mrc" />
                        </span>
                    </div>
                    </>)}
                    {productType === "accessory" && (<>
                        <div className="col-md-6">
                            <label htmlFor="msrp" className="form-label">
                                MSRP<span className="error-text">*</span>
                            </label>
                            <Field
                                className="form-control"
                                type="text"
                                name="msrp"
                                placeholder={"Enter MSRP"}
                                autoComplete="off"
                            />
                            <span className="error-text">
                                <ErrorMessage name="msrp" />
                            </span>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="commission" className="form-label">
                                Commission<span className="error-text">*</span>
                            </label>
                            <Field
                                className="form-control"
                                type="text"
                                name="commission"
                                placeholder={"Enter Commission"}
                                autoComplete="off"
                            />
                            <span className="error-text">
                                <ErrorMessage name="commission" />
                            </span>
                        </div>
                    </>)}
                    {/* <div className="col-md-6">
                        <label htmlFor="Non Commissionable" className="form-label">
                            Non Commissionable
                        </label>
                        <Field
                            className="form-control"
                            type="text"
                            name="Non Commissionable"
                            placeholder={"Enter Non Commissionable"}
                            autoComplete="off"
                        />                        
                    </div> */}
                    <div className="col-md-12">
                        <DefaultButton
                            buttonClass={"btn btn-secondary me-2"}
                            buttonTitle={CANCEL}
                            actionBlock={cancel}
                        />
                        <DefaultButton
                            buttonClass={"default-btn-color"}
                            buttonTitle={SAVE}
                            buttonType={"submit"}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default ProductForm;
