import { AgGridReact } from 'ag-grid-react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useSearchParams } from 'react-router-dom'
import { setNextPrevIds } from '../../../containers/next_prev_redux/Actions'
import { addTableFilter } from '../../../containers/table_filters_redux/Actions'
import { EXPORT } from '../../constants/Constants'
import SidebarFilters from './SidebarFilter/SidebarFilters'
import { actionValueRenderer, checkboxRenderer, progressBarRenderer,  getComparators, getFilterParams, getFloatingFilterComponents, getValueFormatters, hyperlinkRenderer, iconRenderer, jsonRenderer, ToolTip, onColumnVisible } from './tableFunctions'
import './TableStyle.scss'

const ListView = ({ columnNames, rowData = [], rowClick, exportTitle, clickView, clickEdit, clickDelete,clickDownload, shouldSetNextPrevIds = false, activeEmployeeFilter, setActiveFilter, tableHeight = '65vh', pdfBtnUrl, pact }) => {
    console.log("columnNames", columnNames)
    const [gridApi, setGridApi] = useState(null);
    const [columnApi, setColumnApi] = useState(null);
    const [originalColumnDefs, setOriginalColumnDefs] = useState([])
    const [adjustedColumnDefs, setAdjustedColumnDefs] = useState([])
    const [exportEnabled, setExportEnabled] = useState(false);
    const [hiddenColumns, setHiddenColumns] = useState(() => {
        let hiddenColumns = [];
        for (let col of columnNames) {
            if (col.hide) {
                hiddenColumns.push(col.field)
            }
        }
        return hiddenColumns
    })
    let [selectedIds, setSelectedIds] = useState([])

    const dispatch = useDispatch();
    const location = useLocation();

    const [filters, setFilters] = useState({}) // this is only used to pass to the sidebar

    let tableKey = location.pathname + "/" + exportTitle // used for saving and loading table filters. Multiple tables have the same exportTitle

    const gridRef = useRef(null);

    let [query] = useSearchParams();

    let { tableFilters } = useSelector((state) => state.tableFilter)
    let initialFilter = getInitialFilter(query.get('status')?.toLowerCase(), query.get('filter'), tableFilters[tableKey]);
    useEffect(() => {
        if (query.get('filter') == 'none') {
            dispatch(addTableFilter(tableKey, {}))
        }
    }, [])

    //An object containing valueFormatters. Different formatting for diferent column types
    let valueFormatters = getValueFormatters();
    //an object containing filterParams. Different filterParams depending on the column type
    let filterParams = getFilterParams();
    let comparators = getComparators();
    let floatingFilterComponents = getFloatingFilterComponents();

    const [columnNamesState, setColumnNamesState] = useState([])
    useEffect(() => {
        if (JSON.stringify(columnNames) !== JSON.stringify(columnNamesState)) {
            setColumnNamesState(columnNames)
        }
    }, [columnNames])

    function resizeColumns(params) {
        function getTotalColumnWidths() {
            let total = 0;
            if (params?.columnApi && params.columnApi.getColumnState()) {
                for (let column of params.columnApi.getColumnState()) {
                    if (!column.hide) {
                        total += column.width;
                    }
                }
            }
            return total;
        }

        let totalColumnWidth = getTotalColumnWidths();
        let tableContainer = document.getElementById('table-container');

        if (totalColumnWidth && tableContainer && originalColumnDefs.length > 0 && totalColumnWidth < tableContainer.offsetWidth) {
            let newColumnDefs = []
            for (let column of originalColumnDefs) {
                if (!column.checkboxSelection && column.field !== 'actions') {
                    newColumnDefs.push({
                        ...column,
                        flex: 1
                    })
                } else {
                    newColumnDefs.push({ ...column })
                }
            }
            setAdjustedColumnDefs(newColumnDefs)
        } else {
            setAdjustedColumnDefs(originalColumnDefs)
        }
        // else if (!columnsAreFlexed){
        //     params.columnApi.autoSizeAllColumns();
        //     totalColumnWidth = getTotalColumnWidths();
        //     if (totalColumnWidth < tableContainer.offsetWidth){
        //         gridApi.sizeColumnsToFit();
        //     }
        // }
    }
    const onGridReady = (params) => {
        setGridApi(params.api);
        setColumnApi(params.columnApi)
        params.api.setFilterModel(initialFilter);

        if (shouldSetNextPrevIds) {
            let ids = []
            params.api.forEachNodeAfterFilterAndSort((node) => {
                ids.push(node.data.id)
            })
            dispatch(setNextPrevIds(ids, exportTitle))
        }
    }
    const onFilterOrSortChanged = (event) => {
        if (shouldSetNextPrevIds) {
            let ids = []
            event.api.forEachNodeAfterFilterAndSort((node) => {
                ids.push(node.data.id)
            })
            dispatch(setNextPrevIds(ids, exportTitle))
        }
        let currentFilters = event.api.getFilterModel();
        setFilters(currentFilters)
        console.log(currentFilters)
        if (JSON.stringify(tableFilters[tableKey]) != JSON.stringify(currentFilters)) {
            dispatch(addTableFilter(tableKey, currentFilters))
        }
    }
    const onBtnExport = () => {
        let exportableColumns = columnApi.columnModel.getColumnState().map((col, index) => {
            if (!col.hide || col.colId == 'id') {
                if (col.colId != 'selection checkbox' && col.colId != 'actions') {
                    return col.colId
                }
            }
        }).filter(col => col != undefined)
        gridApi.exportDataAsCsv(
            {
                fileName: (exportTitle ?? '') + "_" + new Date().toISOString().slice(0, 10),
                allColumns: false,
                onlySelected: true,
                columnKeys: exportableColumns,
                processCellCallback: (params) => {
                    let statuses = ['Active', 'Inactive', 'Future']
                    if (params.column.colDef.field === 'active' && statuses[params.value]) {
                        return statuses[params.value]
                    }
                    return params.value
                }
            }
        );
    };

    const onBtnExportPDF = () => {   
        let url = pdfBtnUrl+selectedIds;    
        window.open(url, '_blank');
    };

    const onSelectionChanged = useCallback((event) => {        
        setExportEnabled(event.api.getSelectedNodes().length > 0);
        let onPageSelected = event.api.getSelectedNodes()
        let alreadySelected = [...selectedIds];

        //Add new ids
        for (let selection of onPageSelected) {
            if (!alreadySelected.some((v) => v === selection.data.id)) {
                alreadySelected.push(selection.data.id);
            }
        }
        //remove unselected ids
        let newSelectedIds = alreadySelected.filter((selection) => {
            let isOnPage = false;
            event.api.forEachNode((row) => {
                if (row.data.id === selection) {
                    isOnPage = true
                }
            })
            if (!isOnPage) {
                return true;
            }
            return onPageSelected.some((v) => v.data.id === selection)

        })
        selectedIds = newSelectedIds
        console.log(selectedIds);
        setSelectedIds(newSelectedIds)
        
    })
    useEffect(() => {
        let newColumnDefs = [
            {
                colId: 'selection checkbox',
                checkboxSelection: true,
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                width: "55px",
                pinned: 'left'
            },
            ...columnNames.map((column, index) => {
                if (column.field === 'actions') {
                    return {
                        headerName: column.display_name,
                        field: column.field,
                        cellRendererFramework: actionValueRenderer,
                        cellRendererParams: { clickView,clickEdit, clickDelete, clickDownload },
                        hide: column.hide ?? false,
                        suppressMenu: true,
                        width: column.width,
                        pinned: column.pinned,
                        wrapText:true, // for text wrapping
                        autoHeight:true, // for text wrapping
                    }
                } else if (column.type === 'checkbox') {
                    return {
                        headerName: column.display_name,
                        field: column.field,
                        hide: column.hide ?? false,
                        suppressMenu: true,
                        floatingFilter: false,
                        cellRendererFramework: checkboxRenderer,
                        pinned: column.pinned,
                        wrapText:true, // for text wrapping
                        autoHeight:true, // for text wrapping
                    }
                } else if (column.type === 'progressbar') {
                    return {
                        headerName: column.display_name,
                        field: column.field,
                        hide: column.hide ?? false,
                        suppressMenu: true,
                        floatingFilter: false,
                        cellRendererFramework: progressBarRenderer,
                        pinned: column.pinned,
                        wrapText:true, // for text wrapping
                        autoHeight:true, // for text wrapping
                        cellStyle: {
                            'padding-top': '7px',
                            'width': '100px !important',
                            
                          }
                    }
                } else if (column.type === 'icon') {
                    return {
                        headerName: column.display_name,
                        field: column.field,
                        hide: column.hide ?? false,
                        filter: 'icon',
                        floatingFilter: false,
                        floatingFilterComponent: floatingFilterComponents[column.field === 'covering' || column.field === 'covering_store' ? 'checkbox' : 'text'],
                        icon: column.icon ?? '',
                        suppressMenu: true,
                        width: column.width,
                        cellRendererFramework: iconRenderer,
                        pinned: column.pinned,
                        wrapText:true, // for text wrapping
                        autoHeight:true, // for text wrapping
                    }
                } else if (column.type === 'commisionable') {
                    return {
                        headerName: column.display_name,
                        field: column.field,
                        hide: column.hide ?? false,
                        filter: 'commisionable',
                        floatingFilter: false,
                        floatingFilterComponent: floatingFilterComponents[column.field === 'is_commisionable' ? 'checkbox' : 'text'],
                        icon: column.icon ?? '',
                        suppressMenu: true,
                        width: column.width,
                        cellRendererFramework: iconRenderer,
                        pinned: column.pinned,
                        wrapText:true, // for text wrapping
                        autoHeight:true, // for text wrapping
                    }
                } else if (column.type === 'jsonLog') {
                    return {
                        headerName: column.display_name,
                        field: column.field,
                        filter: 'text',
                        floatingFilter: false,
                        floatingFilterComponent: floatingFilterComponents[column.type],
                        filterParams: filterParams[column.type],
                        valueFormatter: valueFormatters[column.type],
                        comparator: comparators[column.type],
                        suppressMenu: true,
                        sort: column.sort,
                        sortable: true,
                        cellRendererFramework: jsonRenderer,
                        pinned: column.pinned,
                        wrapText:true, // for text wrapping
                        autoHeight:true, // for text wrapping
                        cellClass: "cell-left-align",
                        cellStyle: {
                            'white-space': 'pre-wrap',
                            'word-wrap': 'break-word',
                            'line-height' : 'normal',
                            'padding-bottom': '7px',
                            "text-align": "left"
                          }
                    }
                } else if (column.type === 'hyperlink') {
                    let redirect_link = column.redirect_link;
                    let redirect_value = column.redirect_value;
                    let is_workflow_link = column.is_workflow_link;
                    let functionTo = column.functionTo;
                    return {
                        headerName: column.display_name,
                        field: column.field,
                        hide: column.hide ?? false,
                        // floatingFilter: false,
                        cellRendererFramework: hyperlinkRenderer,
                        cellRendererParams: { redirect_link, redirect_value, is_workflow_link, functionTo },
                        width: column.width,
                        suppressMenu: true,
                        pinned: column.pinned,
                        wrapText:true, // for text wrapping
                        autoHeight:true, // for text wrapping
                        cellClass: "cell-left-align",
                        cellStyle: {
                            'white-space': 'pre-wrap',
                            'word-wrap': 'break-word',
                            'line-height' : 'normal',
                            'padding-bottom': '7px',
                            "text-align": "left"
                          }
                    }
                }
                else {
                    return {
                        comparator: comparators[column.type],
                        sort: column.sort,
                        sortable: true,
                        headerName: column.display_name,
                        valueFormatter: valueFormatters[column.type],
                        field: column.field,
                        filter: (column.type === 'active' || column.type === 'checkbox') ? 'text' : column.type === 'date' ? 'custom_date' : column.type,
                        suppressMenu: true,
                        floatingFilter: false,
                        floatingFilterComponent: floatingFilterComponents[column.type],
                        floatingFilterComponentParams: {
                            suppressFilterButton: column.type === 'active' || column.type === 'checkbox' || column.type === 'dropdown',
                            dropdownOptions: column.type === 'dropdown' ? column.dropdownOptions : null,
                            dropdownFunction: column.dropdownFunction
                        },
                        filterParams: filterParams[column.type],
                        hide: column.hide ?? false,
                        tooltipField: column.field,
                        tooltipComponent: ToolTip,
                        width: column.width,
                        pinned: column.pinned,                       
                        wrapText:false, // for text wrapping
                        autoHeight:true, // for text wrapping
                        cellClass: "cell-left-align",
                        cellStyle: {
                            'white-space': 'pre-wrap',
                            'word-wrap': 'break-word',
                            'line-height' : 'normal',
                            'padding-bottom': '7px',
                            "text-align": "left"
                          }
                    }
                }
            })
        ]
        if (newColumnDefs.length > 0) {
            setOriginalColumnDefs(newColumnDefs)
            setAdjustedColumnDefs(newColumnDefs)
        }
    }, [columnNamesState])

    useEffect(() => {
        if (originalColumnDefs.length > 0 && columnApi) {
            //On the EmpTypeHireStatusTrackerGetAll page the column api would return [] instead of the list of columns and mess up the sizing. 
            //This timeout makes it work correctly
            setTimeout(() => {
                resizeColumns({ columnApi })
            }, 0)
        }
    }, [originalColumnDefs, columnApi, rowData])
    useEffect(() => {
        if (shouldSetNextPrevIds && Array.isArray(rowData)) {
            let ids = []
            for (let row of rowData) {
                ids.push(row.id);
            }
            dispatch(setNextPrevIds(ids, exportTitle));
        }
    }, [rowData])
    const rowClassRules = {
        'not-current-row': (params) => params.data.current_manager === 'no',
      };
    function getRowStyleScheduled(params) {
        if (params.data.mds_message === 'Missing data or not able to process Termination') {
            return {
                'background-color': '#FFADCA',
                'color': '#181d1f'
            }
        } else if (params.data.status === 'pending') {
            return {
                'background-color': '#D7BDE2',
                'color': '#181d1f'
            }
        } else if (params.data.status === 'odoo_pending') {
            return {
                'background-color': '#ffdfc6',
                'color': '#181d1f'
            };
        }
        return null;
    };

    if (rowData.length > 0) {
        return (
            <div id="table-container" style={{ width: '100%', height: '100%' }}>
                {!pact && !pdfBtnUrl && (
                <Button
                    style={{ width: 115 }}
                    className={"default-btn-color mb-1 py-1"}
                    onClick={() => onBtnExport()}
                    disabled={!exportEnabled}
                >
                    {EXPORT}
                </Button>
                  )}
                {pdfBtnUrl && (
                    <Button
                        style={{ width: 115 }}
                        className={"default-btn-color mb-1 py-1 mx-1"}
                        onClick={() => onBtnExportPDF()}
                        disabled={!exportEnabled}
                    >
                        {'Export PDF'}
                    </Button>
                )}
                {!pact  && (
                    <Button
                        style={{ width: 115 }}
                        className={"default-btn-color mb-1 mx-1 py-1"}
                        onClick={() => { gridApi.setFilterModel(null) }}
                    >
                        {'Clear Filters'}
                    </Button>
                )}
                <Row className="gx-0">
                    <Col>
                        <div
                            id="myGrid"
                            style={{
                                height: `${tableHeight}`, 
                                width: '100%',
                            }}
                            className="ag-theme-alpine"
                        >
                            {adjustedColumnDefs.length ? <AgGridReact
                                ref={gridRef}
                                rowData={rowData}
                                pagination={true}
                                paginationPageSize={80}
                                onGridReady={onGridReady}
                                onRowClicked={rowClick}
                                onFilterChanged={onFilterOrSortChanged}
                                onSortChanged={onFilterOrSortChanged}
                                sortingOrder={['desc', 'asc']}
                                suppressRowClickSelection={true}
                                rowSelection="multiple"
                                columnDefs={adjustedColumnDefs}
                                enableBrowserTooltips={true}
                                tooltipShowDelay={300}
                                onSelectionChanged={onSelectionChanged}
                                rowBuffer={80}
                                getRowStyle={getRowStyleScheduled}
                                onColumnVisible={(event) => onColumnVisible(event, setHiddenColumns)}
                                rowClassRules={rowClassRules}
                            /> : <></>
                            }
                        </div >
                    </Col>
                    {!pact  && (
                        <Col sm="auto">
                            <SidebarFilters 
                            colDefs={columnNames} 
                            grid={gridRef.current} 
                            filtersFromTable={filters} 
                            hiddenColumns={hiddenColumns} 
                            setHiddenColumns={setHiddenColumns}
                            barHeight = {tableHeight}>

                            </SidebarFilters>
                        </Col>
                    )}
                </Row>
            </div >
        )
    } else {
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <center>No Data to Show </center>
            </div>
        )
    }


    //stateFilter is the filter stored in the state
    function getInitialFilter(queryParam, filterParam, stateFilter) {
        let initialFilter = {};
        switch (queryParam) {
            case 'active':
                initialFilter = {
                    active: {
                        filterType: 'text',
                        type: 'equals',
                        filter: '0',
                    }
                }
                break;
            case 'inactive':
                initialFilter = {
                    active: {
                        filterType: 'text',
                        type: 'equals',
                        filter: '1'
                    }
                }
                break;
            case 'future':
                initialFilter = {
                    active: {
                        filterType: 'text',
                        type: 'equals',
                        filter: '2',
                    }
                }
                break;
        }
        if (setActiveFilter) {
            initialFilter.active = {
                filterType: 'text',
                type: 'equals',
                filter: '0',
            }
        }
        if (filterParam === 'none' || queryParam === 'active') {
            if (activeEmployeeFilter) {
                initialFilter.emp_status = {
                    filter: "Active",
                    filterType: "text",
                    type: "contains"
                }
            }
            return initialFilter
        } else if (filterParam) {
            initialFilter = {
                ...JSON.parse(filterParam)
            }
        } else if (stateFilter) {
            initialFilter = {
                ...stateFilter
            }
        }
        return initialFilter
    }

}

export default ListView
