import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../../shared/components/Breadcrumbs'
import '../Dashboard.css'
import { DASHBOARD } from '../../../shared/constants/Constants';
import { loadDashboardDataRequest, loadDashboardGetRangeDataRequest, loadDashboardGetStoreDataRequest, loadDashboardGetStoreMetrics } from '../redux/Actions';
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Card, Stack } from 'react-bootstrap';
import { loadCompanyDataRequest } from '../../company/redux/Actions';
import DatePicker from 'react-datepicker';
import { ResponsiveContainer, Bar, BarChart, AreaChart, Area, LineChart, Line, CartesianGrid, Legend, XAxis, YAxis, Tooltip, Cell } from 'recharts';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {

	let dispatch = useDispatch();
	let { dashboardCount, empCount, storeCount, storeMetrics } = useSelector(state => state.dashboardCount);
	let { companies } = useSelector(state => state.company)

	let [selectedCompany, setSelectedCompany] = useState(0);
	let navigate = useNavigate();
	const pastDate = new Date();
	pastDate.setFullYear(pastDate.getFullYear(), pastDate.getMonth() - 1);

	//Redirect
	let { login } = useSelector((state) => state.login);

	if (login?.role === "hc_sales") {
		navigate('/t-shirtSize')
	}

	let currentYear = new Date().getFullYear();

	let [managersStartDate, setManagersStartDate] = useState(pastDate.toDateString())
	let [managersEndDate, setManagersEndDate] = useState(new Date().toDateString())

	let [empStatusStartDate, setEmpStatusStartDate] = useState(pastDate.toDateString())
	let [empStatusEndDate, setEmpStatusEndDate] = useState(new Date().toDateString())
	useEffect(() => {
		dispatch(loadDashboardDataRequest());
		dispatch(loadDashboardGetStoreMetrics());
		dispatch(loadCompanyDataRequest());
		dispatch(loadDashboardGetRangeDataRequest(pastDate, new Date(), selectedCompany))
		dispatch(loadDashboardGetStoreDataRequest(currentYear, selectedCompany))
	}, [dispatch]);

	useEffect(() => {
		if (empStatusStartDate && empStatusEndDate) {
			dispatch(loadDashboardGetRangeDataRequest(empStatusStartDate, empStatusEndDate, selectedCompany))
		}
	}, [empStatusStartDate, empStatusEndDate, selectedCompany])

	useEffect(() => {
		dispatch(loadDashboardGetStoreDataRequest(currentYear, selectedCompany))

	}, [currentYear, selectedCompany])

	let region = dashboardCount[0]?.region;
	let market = dashboardCount[1]?.market;
	let active_store = dashboardCount[2]?.active_store;
	let employeeData = dashboardCount[3]?.employeeData;
	let densityClassData = dashboardCount[4]?.density_class;
	let storeClassificationData = dashboardCount[5]?.store_classification;
	if (storeClassificationData) {

		for (let entry of storeClassificationData) {
			entry.value = Number(entry.count);
			entry.name = entry.store_classification_name
		}
	}
	let IRSM = 0;
	let MLCM = 0;
	if (storeMetrics[0]?.store_IRSM_MLCM_cnt[0]?.job_title == "Interim Retail Store Manager") {
		IRSM = storeMetrics[0]?.store_IRSM_MLCM_cnt[0]?.count;
	}
	if (storeMetrics[0]?.store_IRSM_MLCM_cnt[1]?.job_title == "Market Learning Center Manager") {
		MLCM = storeMetrics[0]?.store_IRSM_MLCM_cnt[1]?.count;
	}
	let unRSMs = storeMetrics[1]?.store_Un_RSM_cnt[0]?.count ?? 0;

	let interimDOSO = storeMetrics[2]?.InterimDOSO_cnt?.[0]?.count ?? 0;
	let unassignedDOSO = storeMetrics[3]?.unassignedDOSO_cnt?.[0]?.count ?? 0;
	let interimDSOM = storeMetrics[4]?.InterimDSOM_cnt?.[0]?.count ?? 0;
	let unassignedDSOM = storeMetrics[5]?.unassignedDSOM_cnt?.[0]?.count ?? 0;
	let backupStore = storeMetrics[6]?.backupStore_cnt?.[0]?.count ?? 0;
	let secondaryRsm = storeMetrics[7]?.secondaryRsm_cnt?.[0]?.count ?? 0;


	let hireTerminationData = [
		{
			name: "New Hires",
			count: Number(empCount.EmployeeHires)
		},
		{
			name: "Terminated",
			count: Number(empCount.EmployeeTerms)
		}
	]
	let staticYears = [];
	for (var i = 2010; i <= 2023; i++) {
		let obj = {};
		obj["name"] = i;
		obj["value"] = i;
		staticYears.push(obj)
	}

	let closedStoreData = storeCount;

	const changeSelectedCompany = (value) => {
		dispatch(loadDashboardDataRequest(value))
		dispatch(loadDashboardGetStoreMetrics(value))
		setSelectedCompany(value > 0 ? value : null);
	}
	const changeSelectedYear = (value) => {
		dispatch(loadDashboardGetStoreDataRequest(value, selectedCompany))
	}

	localStorage.setItem("selectedId", selectedCompany)
	console.log(storeClassificationData)
	return (
		<>
			<Row className="dashboard">
				<Col xs={12} sm={"auto"}>
					<Breadcrumbs
						children={[
							{ label: DASHBOARD },
						]}
					/>
				</Col>
				<Col></Col>
				<Col xs={12} sm={'auto'}>
					<div className="float-end mb-1">
						<select className="float-end form-control" onChange={(e) => {
							changeSelectedCompany(e.target.value)
						}}>
							<option value={0}>All Companies</option>
							{companies.map((company) =>
								<option key={company.id} value={company.id} >{company.company_name}</option>
							)}
						</select>
					</div>
				</Col>
			</Row>
			<Row className="gy-1 dashboard">
				<Col xs={12} md={6} lg={3}>
					<StatTile
						text={"Active Regions"}
						number={region}
						color="#09b8e4"
						onClick={() => navigate('/region?status=active')}
						icon={'fas fa-globe'}
					/>
				</Col>
				<Col xs={12} md={6} lg={3}>
					<StatTile
						text="Active Districts"
						number={market}
						color={"#e9366b"}
						onClick={() => { navigate('/district?status=active') }}
						icon='fas fa-city'
					/>
				</Col>
				<Col xs={12} md={6} lg={3}>
					<StatTile
						text="Active Stores"
						number={active_store}
						color={"#fa9b1e"}
						onClick={() => { navigate('/stores?status=active') }}
						icon='fas fa-store'
					/>
				</Col>
				<Col xs={12} md={6} lg={3}>
					<StatTile
						text="Active Employees"
						number={employeeData}
						color={"#1fb81f"}
						onClick={() => { navigate('/employees?filter={"emp_status":{"filterType":"text","type":"contains","filter":"Active"}}') }}
						icon='fas fa-users'
					/>
				</Col>
			</Row>
			<Row className={"gy-3 my-1 dashboard"}>
				<Col xs={12} lg={6} className='align-self-stretch'>
					<Card style={{ height: '100%', border: 0, borderLeft: `3px solid #20d020`, boxShadow: "-2px 2px 5px #0002" }}>
						<Card.Body>
							<div className='d-flex align-items-center'>
								<div className='card-title'>
									{/* <span>Managers</span> */}
								</div>
								{/* <div className='d-flex justify-content-end' style={{ width: '100%' }}>
									<div className='mx-3'>
										<DatePicker
											className='float-end form-control'
											selected={(managersStartDate && new Date(managersStartDate)) || pastDate}
											onChange={val => {
												setManagersStartDate(new Date(val).toDateString())
											}}
											placeholderText={"Start Date"}
											autoComplete="off"
										/>
									</div>
									<div>
										<DatePicker
											className='float-end form-control'
											selected={(managersEndDate && new Date(managersEndDate)) || new Date()}
											onChange={val => {
												setManagersEndDate(new Date(val).toDateString())
											}}
											placeholderText={"End Date"}
											autoComplete="off"
										/>
									</div>
								</div> */}
								{/* <div className="widgets-icons-2 rounded-circle text-white ms-auto" style={{ background: `linear-gradient(45deg, #00ee00, 80%, #f28f3420), 100%` }}>
									<i className="fas fa-store"></i>
								</div> */}
							</div>
							<Row>
								<Col>
									<div className="storeRow countRow clickable" onClick={() => {
										navigate(`/employees?filter={` +
											`"job_title_name":{"filterType":"text","type":"contains","filter":"Interim Retail Store Manager"},` +
											`"store_sap_name":{"filterType":"text","type":"notContains","filter":"B000"}}`
										)
									}}>
										<div>
											<i className='fas fa-user'></i>
										</div>
										<div className="storeLabel countRow-label">
											Stores with Interim RSMs
										</div>
										<div className="storeCount">
											{IRSM}
										</div>
									</div>
									<div className="storeRow countRow clickable" onClick={() => {
										navigate(`/stores?filter={` +
											`"manager":{"filterType":"text","type":"blank"},"active":{"filterType":"text","type":"equals","filter":"0"}}`
										)
									}}>
										<div>
											<i className='fas fa-user'></i>
										</div>
										<div className="storeLabel countRow-label">
											Stores with Unassigned RSMs
										</div>
										<div className="storeCount">
											{unRSMs}
										</div>
									</div>
									<div className="storeRow countRow clickable" onClick={() => {
										navigate(`/employees?filter={` +
											`"job_title_name":{"filterType":"text","type":"contains","filter":"Market Learning Center Manager"},` +
											`"store_sap_name":{"filterType":"text","type":"notContains","filter":"B000"}}`
										)
									}}>
										<div>
											<i className='fas fa-user'></i>
										</div>
										<div className="storeLabel countRow-label">
											Stores with MLCM
										</div>
										<div className="storeCount">
											{MLCM}
										</div>
									</div>
									<div className="districtRow countRow clickable" onClick={() => {
										navigate(`/employees?filter={` +
											`"job_title_name":{"filterType":"text","type":"contains","filter":"Interim District Sales and Operations Manager"},` +
											`"emp_status":{"filterType":"text","type":"contains","filter":"Active"}}`)
										}}>

									<div>
											<i className='fas fa-user'></i>
										</div>
										<div className="districtLabel countRow-label">
											Districts with Interim DSOM
										</div>
										<div className="storeCount">
											{interimDSOM}
										</div>
									</div>
									<div className="districtRow countRow clickable" onClick={() => {
										navigate(`/district?filter={` +
											`"manager":{"filterType":"text","type":"blank"},"active":{"filterType":"text","type":"equals","filter":"0"}}`
										)
									}}>
										<div>
											<i className='fas fa-user'></i>
										</div>
										<div className="districtLabel countRow-label">
											Districts without DSOM
										</div>
										<div className="storeCount">
											{unassignedDSOM}
										</div>
									</div>
						         {/* <div className="regionRow countRow clickable" onClick={() => {
										navigate(`/employees?filter={` +
											`"job_title_name":{"filterType":"text","type":"contains","filter":"Interim Director of Sales and Operations"}}`
										)
									    }}>
										<div>
											<i className='fas fa-user'></i>
										</div>
										<div className="regionLabel countRow-label">
											Regions with Interim DOSO
										</div>
										<div className="storeCount">
											{interimDOSO}
										</div>
								        </div>  */}
									<div className="regionRow countRow clickable" onClick={() => {
										navigate(`/region?filter={` +
											`"manager":{"filterType":"text","type":"blank"},"active":{"filterType":"text","type":"equals","filter":"0"}}`
										)
									}}>
								<div> 
											<i className='fas fa-user'></i>
										</div>
										<div className="regionLabel countRow-label">
											Regions without DOSO
										</div>
										<div className="storeCount">
											{unassignedDOSO}
										</div>
									</div>
									<div className="backupRow countRow clickable" onClick={() => {
										navigate(`/employees?filter={` +
											`"store_sap_name":{"filterType":"text","type":"contains","filter":"B000"},` +
											`"start_date":{"filterType":"date","type":"lessThanOrEqual","dateFrom":"${new Date(managersEndDate).toISOString()}"},` +
											`"end_date":{"filterType":"date","type":"greaterThanOrEqual","dateFrom":"${new Date(managersEndDate).toISOString()}"}}`
										)
									}}>
										<div>
											<i className='fas fa-user'></i>
										</div>
										<div className="regionLabel countRow-label">
											RSM's Count in Backup Store
										</div>
										<div className="storeCount">
											{backupStore}
										</div>
									</div>
									<div className="secondaryRow countRow clickable" onClick={() => {
										navigate(`/employees?filter={` +
										    `"emp_status":{"filterType":"text","type":"dropdown","filter":"Active"},`+
											`"group_name":{"filterType":"text","type":"dropdown","filter":"RSM"},` +
											`"is_commisionable":{"filterType":"text","type":"equals","filter":"false"}}`
										)
									}}>
										<div>
											<i className='fas fa-user'></i>
										</div>
										<div className="regionLabel countRow-label">
											Secondary RSM
										</div>
										<div className="storeCount">
											{secondaryRsm}
										</div>
									</div>
								</Col>
								{/* <Col xs={2}>
									<div className="widgets-icons-2 rounded-circle text-white ms-auto" style={{ background: `linear-gradient(45deg, #00ee00, 80%, #f28f3420), 100%` }}>
										<i className="fas fa-store"></i>
									</div>
								</Col> */}
							</Row>
						</Card.Body>
					</Card>
				</Col>
				<Col xs={12} lg={6}>
					<Card style={{ border: 0, borderLeft: `3px solid #6de32d`, boxShadow: "-2px 2px 5px #0002" }}>
						<Card.Body>
							<p className='card-title'>
								Store Classification
							</p>
							<div style={{ height: '400px' }}>
								<ResponsiveContainer height="100%" width="100%">
									{storeClassificationData ? <BarChart
										className='store-classification-chart'
										data={storeClassificationData}
										width={150}
										layout="vertical"
										onClick={(event) => {
											if (event?.activeLabel) {
												navigate(`/stores?filter={"store_classification_name":{"filterType":"text","type":"equals","filter":"${event?.activeLabel}"}}`)
											}
										}}>
										<CartesianGrid strokeDasharray="3 3" horizontal={false} />
										<XAxis type='number' />
										<YAxis type="category" dataKey={'name'} width={120} />
										<Tooltip cursor={false} />
										<Bar barSize={20} dataKey="value" fill="#6de32d" label={{ position: 'right' }} >
											{storeClassificationData?.map((entry, index) => <Cell key={index} fill={['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', ' #e377c2', ' #7f7f7f', ' #bcbd22', ' #17becf'][index % 20]} />)}
										</Bar>
									</BarChart>
										:
										<Row className="justify-content-sm-center">
											<Col xs={'auto'} style={{ marginTop: "auto" }}>
												<span className="align-middle">
													No Data
												</span>
											</Col>
										</Row>
									}
								</ResponsiveContainer>
							</div>
						</Card.Body>
					</Card>
				</Col>
				<Col xs={12} lg={6}>
					<Card style={{ border: 0, borderLeft: `3px solid #4a56ff`, boxShadow: "-2px 2px 5px #0002" }}>
						<Card.Body>
							<div className='d-flex align-items-center'>
								<div className='card-title text-nowrap'>
									<span>Hires / Terminations</span>
								</div>
								<div className='d-flex justify-content-end' style={{ width: '100%' }}>
									<div className='mx-3'>
										<DatePicker
											className='float-end form-control date-select'
											selected={(empStatusStartDate && new Date(empStatusStartDate)) || pastDate}
											onChange={val => {
												setEmpStatusStartDate(new Date(val).toDateString())
											}}
											placeholderText={"Start Date"}
											autoComplete="off"
										/>
									</div>
									<div>
										<DatePicker
											className='float-end form-control date-select'
											selected={(empStatusEndDate && new Date(empStatusEndDate)) || new Date()}
											onChange={val => {
												setEmpStatusEndDate(new Date(val).toDateString())
											}}
											placeholderText={"End Date"}
											autoComplete="off"
										/>
									</div>
								</div>
								{/* <div className="widgets-icons-2 rounded-circle text-white ms-auto" style={{ background: `linear-gradient(45deg, #00ee00, 80%, #f28f3420), 100%` }}>
									<i className="fas fa-store"></i>
								</div> */}
							</div>
							<div style={{ height: '360px' }}>
								<ResponsiveContainer height="100%" width="100%">
									{(hireTerminationData[0].count || hireTerminationData[1].count) ?
										<BarChart data={hireTerminationData} width={150} height={40} barSize={40}>
											<CartesianGrid strokeDasharray="3 3" />
											<XAxis dataKey="name" />
											<YAxis domain={[0, (dataMax) => Math.floor(dataMax * 1.1)]} />
											<Tooltip cursor={false} />
											<Bar dataKey="count" fill="#fa3" label={{ position: 'top', fill: 'black' }} isAnimationActive={false}>
												{hireTerminationData.map((entry, index) => <Cell key={index} fill={['#2ab320', '#e62c2c'][index]} />)}
											</Bar>
										</BarChart>
										:
										<Row className="justify-content-sm-center">
											<Col xs={'auto'} style={{ marginTop: "auto" }}>
												<span className="align-middle">
													No Data
												</span>
											</Col>
										</Row>
									}
								</ResponsiveContainer>
							</div>
						</Card.Body>
					</Card>
				</Col>
				<Col xs={12} lg={6}>
					<Card style={{ border: 0, borderLeft: `3px solid #8c564b`, boxShadow: "-2px 2px 5px #0002" }}>
						<Card.Body>
							<div className='d-flex align-items-center'>
								<div className='card-title text-nowrap'>
									<span>Closed Stores</span>
								</div>
								<div className='d-flex justify-content-end' style={{ width: '100%' }}>
									<div className="float-end mb-1">
										<select className="float-end form-control year-select" onChange={(e) => {
											changeSelectedYear(e.target.value)
										}}>
											{staticYears.map((year) =>
												year.value == currentYear ? <option key={year.value} value={year.value} selected >{year.name}</option> :
													<option key={year.value} value={year.value} >{year.name}</option>
											)}
										</select>
									</div>
								</div>
								{/* <div className="widgets-icons-2 rounded-circle text-white ms-auto" style={{ background: `linear-gradient(45deg, #00ee00, 80%, #f28f3420), 100%` }}>
									<i className="fas fa-store"></i>
								</div> */}
							</div>
							<div style={{ height: '360px' }}>
								<ResponsiveContainer height="100%" width="100%">
									{closedStoreData.length > 0 ?
										<LineChart data={closedStoreData} width={500} height={400}  >
											<CartesianGrid strokeDasharray="3 3" />
											<XAxis dataKey="month" angle={-15} textAnchor='end' />
											<YAxis allowDataOverflow={true} />
											<Tooltip />
											<Line type="monotone" dataKey="count" fill="#e62c2c" label={{ position: 'top', fill: 'black' }}
												stroke="#8884d8" activeDot={{ r: 8 }} />
											{/* <Area type="monotone" dataKey="count" stroke="#8884d8" fill="#8884d8" /> */}
										</LineChart	>
										:
										<Row className="justify-content-sm-center">
											<Col xs={'auto'} style={{ marginTop: "auto" }}>
												<span className="align-middle">
													No Data
												</span>
											</Col>
										</Row>
									}
								</ResponsiveContainer>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default Dashboard

function StatTile({ number, color, onClick, text, icon }) {
	return <Card className="statCard clickable" style={{ borderLeft: `3px solid ${color}` }} onClick={onClick}>
		<Card.Body>
			<div className="d-flex align-items-center">
				<div>
					<p className="mb-0 text-secondary">{text}</p>
					<h4 className="my-1" style={{ color: color }}>{number}</h4>
				</div>
				<div className="widgets-icons-2 rounded-circle text-white ms-auto" style={{ background: `linear-gradient(45deg, ${color}, 80%, #f28f3420), 100%` }}><i className={icon}></i>
				</div>
			</div>
		</Card.Body>
	</Card>
}
