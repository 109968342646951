import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { useEffect, useState } from "react";
import { Card,Button,Collapse,  Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DefaultButton from "../../../shared/components/DefaultButton";
import ListView from "../../../shared/components/listview/ListView"
import { loadMyPlanAddDataRequest,  loadPlanByEmpLocPerGetDataRequest, loadMyPlanGetDataRequest, loadMyPlanNoteAddDataRequest, loadMyPlanMgrNoteAddDataRequest } from "../redux/Actions"
import FormTextAreaField from "../../../shared/components/FormTextAreaField";

//import { CANCEL, DIVISION, EMPLOYEE_DEPARTMENT, EMPLOYEE_STATUS, HIRE_STATUS, HOURLY_SALARY, JOB_TITLE, MARKET, PART_FULL_TIME, REGION, SAVE, STORE } from "../../../shared/constants/Constants";

const MyPlanView = ({ plan, submit, cancel, period, year, view, isView }) => {
    const dispatch = useDispatch();
    const formik = useFormik({});   
    let i = 1;
    let loc_id = plan['loc_id'];
    let { myPlans, myplan,newnote, error, loading } = useSelector((state) => state.myPlans);
    console.log("Plan View", plan, isView);
    
    let empInfo = JSON.parse(localStorage.getItem("employee"));
    
    useEffect(() => {
        console.log("loading loadFormGetDataRequest")
        if(view == 'history') {
            let request = {
                "employee_id" :  plan['employee_id'], 
                "location_id" :  plan['loc_id'],
                "period"  : plan['period'] ,
                "year"  : plan['year'] 
            }
              dispatch(loadPlanByEmpLocPerGetDataRequest(request));  
        } else {
            let request = {
                "employee_id" :  plan['employee_id'], 
                "location_id" :  plan['loc_id'],
                "period"  : period,
                "year"  : year
            }
              dispatch(loadPlanByEmpLocPerGetDataRequest(request));  
        }
           
         // setInitialValues(myplan)  
      }, [dispatch, plan]);
      console.log("Plan View", plan, submit, cancel, period, year, view, isView)
      const handleBlurChange = (event, handleBlur) => {
        // handleBlur(event); // Call Formik's handleBlur
        // const name = event.target.name.split('.');
        // if(initialValues[name[0]][name[1]] != event.target.value ) {
        //     initialValues[name[0]][name[1]] = event.target.value;
        //     saveMyPlan(initialValues, name[0], true); // Call your custom logic
        //     console.log("calling the api")
        // } else {
        //     console.log("Not calling the api")
        // }  
        
        const name = event.target.id.split('-');
        let value = ((event.target.value).trim()  == "" ) ? null : (event.target.value).trim()
        if(myplan["priorities"][name[2]]["today_mgr_notes"] != value ) {
            let requestValue = {
                'note' : event.target.value,
                'id' : null, 
                'business_priority_id': name[1],
                'formInfo' : myplan ,
                'isManager' : true  
            }      
            dispatch(loadMyPlanMgrNoteAddDataRequest(requestValue))
            console.log(`Textarea with ID ${event.target.name} lost focus!`);
            console.log(`Current value: ${event.target.value}`);
        } else {
            console.log("Not calling the api")
        }  
    };

    console.log(myplan)
    const isEmptyObject = (obj) => {
        return obj === undefined || obj === null || (Object.keys(obj).length === 0 && obj.constructor === Object);
    };
    
    
    console.log("plan", plan)
    return (
        <>
            <Row id="download-div">
                <Card className="p-0" style={{ border: '0px' }}>
                    <Card.Body className="p-0">
                        <div className='viewHeader' key={"header"} style={{ backgroundColor: '#0b7572', color: '#FFF', fontSize: '1rem', padding: '10px' }}>
                            <Row className="p-2">
                                <Col className="col-6">Manager: {plan?.full_name}</Col>
                                <Col className="col-6">Location: {plan?.location_name}</Col>
                            </Row>
                            <Row className="p-2">
                                <Col className="col-6">Period: {period} - {year}</Col>
                                <Col className="col-6">Last Updated On: {}</Col>
                            </Row>
                        </div>

                        {isEmptyObject(myplan?.priorities) ? (
                            <Row className="p-2">
                                <Col className="col-12" style={{ fontSize: '1rem', textAlign: 'center' }}>No priorities added</Col>
                            </Row>
                        ) : (
                            <div>
                                {Object.entries(myplan?.["priorities"] || {}).map(([key, priority], index) => (
                                    <div key={index} style={{ marginBottom: '20px' }}>
                                        <Card className="mb-1">
                                            <Card.Header as="h5" style={{   backgroundColor: '#a9a9a9', padding: '7px', borderRadius: '0px', marginTop: '10px', fontSize:'15px', color:'#000'  }}>Business Priority {index+1} - {priority.business_priority}</Card.Header>
                                            <Card.Body className="p-0">
                                                <Row className="m-2">
                                                    <Col className="col-4"><strong>Business Priority</strong></Col>
                                                    <Col className="col-8">
                                                        <Card className="mb-1">
                                                            <Card.Body className="p-1">
                                                                {priority.business_priority}
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <Row className="m-2">
                                                    <Col className="col-4"><strong>KPI to measure</strong></Col>
                                                    <Col className="col-8">
                                                        <Card className="mb-1">
                                                            <Card.Body className="p-1">
                                                                {priority.KPI_to_measure}
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <Row className="m-2">
                                                    <Col className="col-4"><strong>Current State</strong></Col>
                                                    <Col className="col-8">
                                                        <Card className="mb-1">
                                                            <Card.Body className="p-1">
                                                                {priority.current_state}
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <Row className="m-2">
                                                    <Col className="col-4"><strong>My Actions</strong></Col>
                                                    <Col className="col-8">
                                                        <Card className="mb-1">
                                                            <Card.Body className="p-1">
                                                                {priority.my_actions}
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <Row className="m-2">
                                                    <Col className="col-4"><strong>Team Actions</strong></Col>
                                                    <Col className="col-8">
                                                        <Card className="mb-1">
                                                            <Card.Body className="p-1">
                                                                {priority.team_actions}
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <Row className="m-2">
                                                    <Col className="col-4"><strong>Notes</strong></Col>
                                                    <Col className="col-8">
                                                        <Card className="mb-1">
                                                            <Card.Body className="p-1">
                                                                {Object.entries(priority["notes"] || {}).map(([key, note], index) => (
                                                                    <p key={index} style={{ marginBottom: '1px' }}>
                                                                        <strong>{note['emp_name']} - {Object.keys(note)[0]}:</strong> {note[Object.keys(note)[0]]}
                                                                    </p>
                                                                ))}
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                { plan['employee_id'] != empInfo["emp_no"] ? ( 
                                                <Row className="m-2">
                                                    <Col className="col-4"><strong>Manager Notes</strong></Col>
                                                    <Col className="col-8">
                                                        <textarea
                                                            style={{ width: '100%', border: '1px solid #ff7f0e' }}
                                                            id =  {"mgr-" +  priority.id + "-" + key}
                                                            name = {"mgr_" + priority.today_mgr_notes_id}
                                                            defaultValue = {priority.today_mgr_notes}
                                                            onBlur={handleBlurChange}
                                                            placeholder="Please enter manager notes here"
                                                            rows="4"
                                                            cols="50"
                                                        />
                                                    </Col>
                                                </Row>
                                                ) : (  <p> </p> )}
                                            </Card.Body>
                                        </Card>
                                    </div>
                                ))} 
                            </div>
                        )}
                    </Card.Body>
                </Card>
            </Row>
        </>
    );
};

export default MyPlanView;
