import { NavLink } from 'react-router-dom';
import FloatingActiveFilter from './FloatingActiveFilter';
import FloatingCheckboxFilter from './FloatingCheckboxFilter';
import FloatingDropdownFilter from './FloatingDropdownFilter';


export function actionValueRenderer(params) {
    let add, edit, view, del, download;
    
    if (params.clickAdd) {
        add = <span className='add-btn-icon ms-2 ' onClick={() => { params.clickAdd(params.data) }}>
            <i className="bx bx-plus"></i>
        </span>
    }
    if (params.clickEdit) {
        edit = <span className='edit-btn-icon ms-2 me-2 ' onClick={() => { params.clickEdit(params.data) }}>
            <i className="bx bxs-pencil"></i>
        </span>
    }
    if (params.clickView) {
        view = <span className='edit-btn-icon ms-2 me-2 ' onClick={() => { params.clickView(params.data) }}>
            <i className="bx bxs-show"></i>
        </span>
    }
    if (params.clickDownload) {
        download = <span className='edit-btn-icon ms-2 me-2 ' onClick={() => { params.clickDownload(params.data) }}>
            <i className="bx bxs-download"></i>
        </span>
    }

    if (params.clickDelete) {
        let id = [];
        if (params.data.id) {
            id = params.data.id;
        } else {
            let ids = [];
            let empRoles = params.data.data;
            for (let element of empRoles[0]) {
                ids.push(element.id)
            }
            id = ids
        }
        del = <span className='delete-btn-icon' onClick={() => { params.clickDelete(id) }}>
            <i className="bx bxs-trash"></i>
        </span>
    }
    return <div>
        {add}
        {edit}
        {view}
        {del}
        {download}
    </div>
}
export function checkboxRenderer(params) {
    return <input type='checkbox' checked={params.value}></input>

}

export function progressBarRenderer(params) {
    return <span className="progress-icon">
    <div className="progress" 
        style={{ width: `50%`,  border:'1px solid #72767d' }} >
      <div
        className="progress-bar progress-bar-striped"
        role="progressbar"
        style={{ width: `${params.value}%`, backgroundColor: 'green' }}
        aria-valuenow={params.value}
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  </span>

}

export function iconRenderer(params) {
    let className = "";
    if (params.colDef.icon == 'checkbox') {
        className = (params.value) ? "covering_store_checked" : "covering_store";
    }
    return <i className={className}></i>
}

export function jsonRenderer(params) {
    let value = params.data.change_log;
    if (params.data.action_type == "delete") {
        value = "";
    }
    return value
}

export function hyperlinkRenderer(params) {
    if (params.is_workflow_link) {
        let type = params.data['command']
        let href;
        let id = params.data[params.redirect_value]
        if (params.redirect_value === "link") {
            href = params.data['pdf_link'];
        } else {
            href = "https://wvservice.wirelessvision.com/"
            if (type === 'transfer') {
                href += ('WVEmpAssignment/view/' + id)
            } else if (type === 'demotion') {
                href += ('WVEmpDemotion/view/' + id)
            } else if (type === 'promotion') {
                href += ('WVEmpPromotion/view/' + id)
            } else if (type === 'chaintransfer') {
                href += ('WVEmpChainTransfer/view/' + id)
            } else if (type === 'status_change') {
                href += ('WVEmpStatus/view/' + id)
            } else if (type === 'coveringmanager') {
                href += ('WVCoveringManager/view/' + id)
            } else if (type === 'leaveofabsence') {
                href += ('WVLeave/view/' + id)
            }
        }

        return <a href={href} target="blank">view</a>
    } else if (params.functionTo) {
        return <div className = "redirectLink active" onClick={() => { params.functionTo(params.data) }}>
            {params.value}
        </div> 
    }
    let newroleIds = params.data[params.redirect_value];
    if (params.redirect_value === "roles") {
        let role_ids = params.data.roleIds[0].split(",")
        newroleIds = role_ids[0]
    }
    return <NavLink to={params.redirect_link + newroleIds} className="redirectLink">{params.value}</NavLink>
}


export class ToolTip {
    init(params) {
        const eGui = (this.eGui = document.createElement('div'));
        const color = params.color || 'white';
        const data = params.api.getDisplayedRowAtIndex(params.rowIndex).data;

        eGui.classList.add('custom-tooltip');
        eGui.innerHTML = `
            <p>
                <span class"name">Name</span>
            </p>
            <p>
                <span>Country: </span>
                things
            </p>
            <p>
                <span>Total: </span>
                stuff
            </p>
        `;
    }
    getGui() {
        return this.eGui;
    }
}

export function getValueFormatters() {
    return {
        'date': function (params) {
            return params.value
            // if (params.value) {
            //     let date = new Date(params.value)
            //     let month = date.getUTCMonth() + 1;
            //     let day = date.getUTCDate();
            //     let year = date.getUTCFullYear();
            //     return `${month}/${day}/${year}`
            // }
        },
        'active':
            function (params) {
                return ['Active', 'Inactive', 'Future'][params.value]
            },
        'checkbox':
            function (params) {
                return params.value ? '✓' : '✖';
            }
    }
}
export function getComparators() {
    return {
        date: (date1, date2) => {
            var date1Number = new Date(date1);
            var date2Number = new Date(date2);
            if (date1Number === null && date2Number === null) {
                return 0;
            }
            if (date1Number === null) {
                return -1;
            }
            if (date2Number === null) {
                return 1;
            }
            return date1Number - date2Number;
        },
        text: (text1, text2) => {
            if (text1 === null && text2 === null) {
                return 0;
            }
            if (text1 === null) {
                return -1;
            }
            if (text2 === null) {
                return 1;
            }
            if (String(text1).toLowerCase() === String(text2).toLowerCase()) {
                return 0;
            } else if (String(text1).toLowerCase() > String(text2).toLowerCase()) {
                return 1;
            } else {
                return -1;
            }
        },
        number: (num1, num2) => {
            if (num1 === num2) {
                return 0;
            }
            return (Number(num1) - Number(num2));

        }
    }
}
export function getFilterParams() {
    return {
        'date': {
            comparator: (filterLocalDateAtMidnight, cellValue) => {
                const cellDate = new Date(cellValue);
                if (`${cellDate.getUTCFullYear()} ${cellDate.getUTCMonth()} ${cellDate.getUTCDate()}` === `${filterLocalDateAtMidnight.getUTCFullYear()} ${filterLocalDateAtMidnight.getUTCMonth()} ${filterLocalDateAtMidnight.getUTCDate()}`) {
                    return 0;
                }
                return (cellDate - filterLocalDateAtMidnight)
            },
            filterOptions: getFilterOptions('date')
        },
        'text': {
            filterOptions: getFilterOptions('text')
        },
        "dropdown": {
            filterOptions: getFilterOptions('dropdown')
        }
    }
}
export function getFloatingFilterComponents() {
    return {
        active: FloatingActiveFilter,
        dropdown: FloatingDropdownFilter,
        checkbox: FloatingCheckboxFilter,
    }
}

export function getFilterOptions(type) {
    if (type === "text" || type === 'number') {
        return [
            {
                displayKey: "contains",
                displayName: "Contains",
                predicate: function (filterValue, cellValue) {
                    return parseOrCreateArray(filterValue[0]).some((value) => cellValue?.trim().toLowerCase().includes(value.trim().toLowerCase())) ?? false
                },
                numberOfInputs: 1
            },
            {
                displayKey: "notContains",
                displayName: "Doesn't Contain",
                predicate: function (filterValue, cellValue) {
                    return !parseOrCreateArray(filterValue[0]).some((value) => cellValue?.trim().toLowerCase().includes(value.trim().toLowerCase())) ?? false
                },
                numberOfInputs: 1
            },
            {
                displayKey: "equals",
                displayName: "Equals",
                predicate: function (filterValue, cellValue) {
                    return parseOrCreateArray(filterValue[0]).some((value) => cellValue?.trim().toLowerCase() === value.trim().toLowerCase()) ?? false
                },
                numberOfInputs: 1
            },
            {
                displayKey: "notEqual",
                displayName: "Doesn't Equal",
                predicate: function (filterValue, cellValue) {
                    return !parseOrCreateArray(filterValue[0]).some((value) => cellValue?.trim().toLowerCase() === value.trim().toLowerCase()) ?? false
                },
                numberOfInputs: 1
            },
            {
                displayKey: "startsWith",
                displayName: "Start With",
                predicate: function (filterValue, cellValue) {
                    return parseOrCreateArray(filterValue[0]).some((value) => cellValue?.trim().toLowerCase().startsWith(value.trim().toLowerCase())) ?? false
                },
                numberOfInputs: 1
            },
            {
                displayKey: "endsWith",
                displayName: "Ends With",
                predicate: function (filterValue, cellValue) {
                    return parseOrCreateArray(filterValue[0]).some((value) => cellValue?.trim().toLowerCase().endsWith(value.trim().toLowerCase())) ?? false
                },
                numberOfInputs: 1
            },
            {
                displayKey: 'notBlank',
                displayName: 'Is Set',
                predicate: function (filterValue, cellValue) {
                    return !!cellValue;
                },
                numberOfInputs: 0,
            },
            {
                displayKey: 'blank',
                displayName: 'Is Not Set',
                predicate: function (filterValue, cellValue) {
                    return !cellValue;
                },
                // hideFilterInput: true,
                numberOfInputs: 0,
            },
        ]
    } else if (type === 'date') {
        return [
            {
                displayKey: 'date_equals',
                displayName: 'Equals',
                predicate: function (filterValue, cellValue) {
                    let dates = JSON.parse(filterValue[0])
                    return dates.some((dateValue) => new Date(dateValue).toDateString() === new Date(cellValue).toDateString());
                },
                numberOfInputs: 1,
            },
            {
                displayKey: 'date_notEqual',
                displayName: "Doesn't Equal",
                predicate: function (filterValue, cellValue) {
                    let dates = JSON.parse(filterValue[0])
                    return !dates.some((dateValue) => new Date(dateValue).toDateString() === new Date(cellValue).toDateString());
                },
                numberOfInputs: 1,
            },
            {
                displayKey: 'date_lessThan',
                displayName: 'Less Than',
                predicate: function (filterValue, cellValue) {
                    let dates = JSON.parse(filterValue[0])
                    return dates.some((dateValue) => new Date(dateValue) > new Date(cellValue));
                },
                numberOfInputs: 1,
            },
            {
                displayKey: 'date_greaterThan',
                displayName: 'Greater Than',
                predicate: function (filterValue, cellValue) {
                    let dates = JSON.parse(filterValue[0])
                    return dates.some((dateValue) => new Date(dateValue) < new Date(cellValue));
                },
                numberOfInputs: 1,
            },
            {
                displayKey: 'date_inRange',
                displayName: 'In Range',
                predicate: function (filterValue, cellValue) {
                    let dates = JSON.parse(filterValue[0])
                    return dates.some((dateValue) => new Date(dateValue.fromDate) < new Date(cellValue) && new Date(dateValue.toDate) > new Date(cellValue));
                },
                numberOfInputs: 1,
            },
            {
                displayKey: 'date_lessThanOrEqual',
                displayName: 'Less Than or Equal to',
                predicate: function (filterValue, cellValue) {
                    console.log(filterValue, cellValue)
                    let dates = JSON.parse(filterValue[0])
                    return dates.some((dateValue) => new Date(dateValue) >= new Date(cellValue));
                },
                numberOfInputs: 1,
            },
            {
                displayKey: 'date_greaterThanOrEqual',
                displayName: 'Greater Than or Equal to',
                predicate: function (filterValue, cellValue) {
                    console.log(filterValue, cellValue)
                    let dates = JSON.parse(filterValue[0])
                    return dates.some((dateValue) => new Date(dateValue) <= new Date(cellValue));
                },
                numberOfInputs: 1,
            },
            {
                displayKey: 'date_notBlank',
                displayName: 'Is Set',
                predicate: function (filterValue, cellValue) {
                    console.log(cellValue, cellValue !== undefined && cellValue !== '')
                    return cellValue !== undefined && cellValue !== '';
                },
                numberOfInputs: 0,
            },
            {
                displayKey: 'date_blank',
                displayName: 'Is Not Set',
                predicate: function (filterValue, cellValue) {
                    console.log(cellValue)
                    return cellValue === undefined || cellValue === '' || cellValue === null;
                },
                numberOfInputs: 0,
            },
        ]
    } else if (type === 'dropdown') {
        return [
            {
                displayKey: 'inArray',
                displayName: 'inArray',
                predicate: function (filterValue, cellValue) {
                    return parseOrCreateArray(filterValue).includes(cellValue);
                }
            }
        ]
    }
}
function parseOrCreateArray(value) {
    let array;
    try {
        array = JSON.parse(value)
    } catch (e) {
        if (value && Array.isArray(value)) {
            array = value
        } else {
            array = [value]
        }
    }
    return array;
}
export function onColumnVisible(event, setHiddenColumns) {
    if (!event.column) {
        return
    }
    if (event.visible) {
        setHiddenColumns((columns) => {
            return columns.filter((column) => column !== event.column.colId)
        })
    } else {
        setHiddenColumns((columns) => {
            return [...columns, event.column.colId]
        })
    }
}