import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import FormError from "../../../../shared/components/FormError";
import { loadProductAddDataRequest } from "../redux/Actions";
import ProductForm from "./ProductForm";
import { loadPTDataRequest } from "../../productTables/redux/Actions";

const ProductAdd = ({ handleClose, productType }) => {
  const dispatch = useDispatch();
  const [shouldCloseModal, setShouldCloseModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  let { productTables, loading, error } = useSelector((state) => state.productTables);

  useEffect(() => {
    dispatch(loadPTDataRequest()); 
  },[dispatch]);

  // After submition, the post request is done loading, and there's no errors
  useEffect(() => {
    if (shouldCloseModal && !loading) {
      if (!error) {
        handleClose(true);
      } else {
        setErrorMessage(error.msg);
      }
      setShouldCloseModal(false);
    }
  }, [loading, error, shouldCloseModal]);

  function submit(values) {
    dispatch(loadProductAddDataRequest(values));
    setShouldCloseModal(true);
  }
  function cancel() {
    handleClose(false);
  }
  return (
    <>
      <FormError>
        {errorMessage}
      </FormError>
      <ProductForm submit={submit} cancel={cancel} newProductType={productTables?.productTypeData} productType={productType} />
    </>
  );
};

export default ProductAdd;
