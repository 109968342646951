import { useEffect, useState } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import DefaultButton from "../../../shared/components/DefaultButton";
import { DELETE, CANCEL, BACKTOLIST, EDIT, STORES } from "../../../shared/constants/Constants";
import Modalpopup from "../../../shared/components/Modalpopup";
import SAPTracker from "./SAPTracker";
import DensityTracker from "./DensityTracker";
import CommissionCompGroupTracker from './CommissionCompGroupTracker'
import { loadStoreDataRequest, loadStoreDeleteDataRequest, loadStoreGetDataRequest } from "../redux/Actions";
import StoreClassificationTracker from "./StoreClassificationTracker";
import { PrevNextButtons } from "../../../shared/components/PrevNextButtons";
import Permissions from "../../../shared/components/Permissions";
import ManagerTracker from "./ManagerTracker";
import FindModule from "../../../shared/components/FindModule";
import KeyTagModuleList from "../../../shared/components/tagging/KeyTagModuleList";
import Tagging from "../../../shared/components/tagging/Tagging";
export default function StoreDetails() {
    let params = useParams();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [submittedDeleteRequest, setSubmittedDeleteRequest] = useState(false)
    const [shouldReload, setShouldReload] = useState(true);

    let { loading, error, store, sap_tracker, density_tracker, comm_comp_tracker, store_class_tracker, manager_history_tracker } = useSelector((store) => store.store);
    useEffect(() => {
        if (shouldReload && !loading) {
            setShouldReload(false)
            dispatch(loadStoreGetDataRequest(params.id)) ///Load store data
        }
    }, [shouldReload, loading, params.id])

    useEffect(() => {
        dispatch(loadStoreGetDataRequest(params.id)) ///Load store data

    }, [params.id])

    //Delete Functionality
    const handleDelete = () => {
        let deleteRequest = { id: [params.id] };
        dispatch(loadStoreDeleteDataRequest(deleteRequest));
        navigate('/stores')
    };

    let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    let module_name = "Stores";
    let permission = Permissions(emp_no, module_name);

    let empInfo = JSON.parse(localStorage.getItem('employee'));
    let securityTab = false;
    if (empInfo?.role === "admin") {
        securityTab = true;
    }

    //Find Module
    let selectedModule = FindModule(module_name);
    let keytagModules = KeyTagModuleList(selectedModule?.id);  

    let taggingTab = false;
    let trackers = {};
    trackers['SAP Tracker'] = <SAPTracker store={store} sap_tracker={sap_tracker} setShouldReload={setShouldReload} companyId={store?.company_id} />;
    if (securityTab) {
        trackers['Density Class Tracker'] = <DensityTracker store={store} density_tracker={density_tracker} setShouldReload={setShouldReload} />
        trackers['Comm Comp Tracker'] = <CommissionCompGroupTracker store={store} trackers={comm_comp_tracker} setShouldReload={setShouldReload} />
    }
    trackers['Store Classification'] = <StoreClassificationTracker store={store} store_class_tracker={store_class_tracker} setShouldReload={setShouldReload} />
    trackers['Store Managers'] = <ManagerTracker manager_history_tracker={manager_history_tracker} />

    if (keytagModules.length !== 0) {
        taggingTab = true;
        trackers['Additional Attribute'] = <Tagging resource_value={store?.id} resource_id={selectedModule?.id} />;        
    }

    let [currentTracker, setCurrentTracker] = useState('SAP Tracker')

    return <div>
        <Row className="detailsPageHeader">
            <Col xl={4} xs={12}>
                <Breadcrumbs
                    children={[
                        { href: '/stores', label: STORES },
                        { href: '/stores/' + params.id, label: (store?.store_name ?? "") + ", " + (store?.sap ?? '') },
                    ]}
                />
            </Col>
            <Col xl={8} xs={12}>
                <div className='float-end' >
                    <PrevNextButtons currentId={params.id} basePath={'/stores/'} name={STORES} />
                    <Link to='/stores'>
                        <DefaultButton buttonClass='default-btn-color' buttonTitle={BACKTOLIST} />
                    </Link>
                    {permission.write_permission ? (<>
                        <DefaultButton buttonClass='btn btn-primary default-btn-color ms-1' buttonTitle={EDIT} actionBlock={() => {
                            navigate("/stores/edit/" + store?.id);
                        }} />
                    </>) : (<></>)}
                    {permission.delete_permission ? (<>
                        <Button className="btn btn-danger ms-1" onClick={() => { setShowDeleteModal(true) }}>
                            <i className="fa fa-trash"></i>
                        </Button>
                    </>) : (<></>)}
                </div>
            </Col>
        </Row>
        <Row>
            <Card>
                <Card.Body>
                    <Row>
                        <Col>
                            <dl className='row'>
                                <dt className='col-sm-4'>SAP</dt>
                                <dd className='col-sm-8'>{store?.sap}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Store Name</dt>
                                <dd className='col-sm-8'>{store?.store_name}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>T-Mobile Name</dt>
                                <dd className='col-sm-8'>{store?.tmob_name}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Sprint SAP</dt>
                                <dd className='col-sm-8'>{store?.sprint_sap}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Channel Name</dt>
                                <dd className='col-sm-8'>{store?.channel_name}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Acquisition Dealer</dt>
                                <dd className='col-sm-8'>
                                    <Link to={'/acquisition?filter=none'}>
                                        {store?.acquisition_dealer_name}
                                    </Link>
                                </dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Sub Dealer id</dt>
                                <dd className='col-sm-8'>{store?.sub_dealer_id}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>District Name</dt>
                                <dd className='col-sm-8'>
                                    <Link to={"/district/" + store?.market_id}>
                                        {store?.market}
                                    </Link>
                                </dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Density Class</dt>
                                <dd className='col-sm-8'>
                                    <Link to={'/density?filter=none'}>
                                        {store?.density_class_name}
                                    </Link>
                                </dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Comm Comp Group</dt>
                                <dd className='col-sm-8'>
                                    <Link to={"/commission-comp-group?filter=none"}>
                                        {store?.commission_comp_group_name}
                                    </Link>
                                </dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Store Classification</dt>
                                <dd className='col-sm-8'>
                                    <Link to={"/store-classification?filter=none"}>
                                        {store?.store_classification_name}
                                    </Link>
                                </dd>
                            </dl>
                            <dl className="row">
                                <dt className="col-sm-4">Manager</dt>
                                <dd className="col-sm-8">
                                    <Link to={`/employees/${store?.manager_id}`}>
                                        {store?.manager_name}
                                    </Link>
                                </dd>
                            </dl>
                        </Col>
                        <Col>
                            <dl className='row'>
                                <dt className='col-sm-4'>Address</dt>
                                <dd className='col-sm-8'>{store?.address_street}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>City</dt>
                                <dd className='col-sm-8'>{store?.city}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>County</dt>
                                <dd className='col-sm-8'>{store?.county}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>State</dt>
                                <dd className='col-sm-8'>{store?.state}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Zip</dt>
                                <dd className='col-sm-8'>{store?.zip}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Phone</dt>
                                <dd className='col-sm-8'>{store?.phone}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Website</dt>
                                <dd className='col-sm-8'>{store?.website}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Acquisition Date</dt>
                                <dd className='col-sm-8'>{store?.acquisition_date}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Open Date</dt>
                                <dd className='col-sm-8'>{store?.open_date}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Close Date</dt>
                                <dd className='col-sm-8'>{store?.closed_date}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Temp Closed Date</dt>
                                <dd className='col-sm-8'>{store?.temp_closed_date}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Relocation date</dt>
                                <dd className='col-sm-8'>{store?.relocation_date}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Comments</dt>
                                <dd className='col-sm-8'>{store?.comments}</dd>
                            </dl>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Row>
        <Row style={{ marginTop: '5px' }}>
            <Col xs={6}>
                <h6 className='mt-4 mb-0 text-uppercase'>Store Trackers</h6>
                <hr></hr>
            </Col>
        </Row>
        <Row>
            <Col>
                {Object.keys(trackers).map((key) => {
                    return <button className={currentTracker === key ? "top-tab selected" : "top-tab"} onClick={() => { setCurrentTracker(key) }}>{key}</button>
                })
                }
            </Col>
            <Card >
                <Card.Body >
                    {trackers[currentTracker]}
                </Card.Body>
            </Card>
        </Row>
        <Modalpopup
            show={showDeleteModal}
            handleClose={() => { setShowDeleteModal(false) }}
            actionTitle={"Delete Store"}
        >
            <p>Are you sure you want to Delete?</p>
            <div className="col-md-12">
                <DefaultButton
                    buttonClass={"btn btn-secondary me-2"}
                    buttonTitle={CANCEL}
                    actionBlock={() => { setShowDeleteModal(false) }}
                />
                <DefaultButton
                    buttonClass={"btn btn-danger"}
                    buttonTitle={DELETE}
                    actionBlock={handleDelete}
                />
            </div>
        </Modalpopup>
    </div>
}