
//Get
const MYPLAN_GET_DATA = {
  MYPLAN_GET_DATA_LOAD: "MYPLAN_GET_DATA_LOAD",
  MYPLAN_GET_DATA_SUCCESS: "MYPLAN_GET_DATA_SUCCESS",
  MYPLAN_GET_DATA_FAIL: "MYPLAN_GET_DATA_FAIL",
};

const MYPLAN_GET_DETAIL_API = "/pactplan/getByLoc/";
//const MYPLAN_GET_DETAIL_API = "/pactplan/getPlanByLoc/";

//Get my Locations
const MYLOC_GET_DATA = {
  MYLOC_GET_DATA_LOAD: "MYLOC_GET_DATA_LOAD",
  MYLOC_GET_DATA_SUCCESS: "MYLOC_GET_DATA_SUCCESS",
  MYLOC_GET_DATA_FAIL: "MYLOC_GET_DATA_FAIL",
};
const MYLOC_GET_DETAIL_API = "/pactplan/getMyLoc/";

//Get
const TEAMPLAN_GET_DATA = {
  TEAMPLAN_GET_DATA_LOAD: "TEAMPLAN_GET_DATA_LOAD",
  TEAMPLAN_GET_DATA_SUCCESS: "TEAMPLAN_GET_DATA_SUCCESS",
  TEAMPLAN_GET_DATA_FAIL: "TEAMPLAN_GET_DATA_FAIL",
};

const TEAMPLAN_GET_DETAIL_API = "/pactplan/getTeamPriority/";

//Get
const PLAN_BY_LOC_LEVEL_GET_DATA = {
  PLAN_BY_LOC_LEVEL_GET_DATA_LOAD: "PLAN_BY_LOC_GET_DATA_LOAD",
  PLAN_BY_LOC_LEVEL_GET_DATA_SUCCESS: "PLAN_BY_LOC_GET_DATA_SUCCESS",
  PLAN_BY_LOC_LEVEL_GET_DATA_FAIL: "PLAN_BY_LOC_GET_DATA_FAIL",
};

const PLAN_BY_LOC_LEVEL_GET_DETAIL_API = "/pactplan/getPlansByLocation/";

// Create
const MYPLAN_ADD_DATA = {
  MYPLAN_ADD_DATA_LOAD: "MYPLAN_ADD_DATA_LOAD",
  MYPLAN_ADD_DATA_SUCCESS: "MYPLAN_ADD_DATA_SUCCESS",
  MYPLAN_ADD_DATA_FAIL: "MYPLAN_ADD_DATA_FAIL",
};
const MYPLAN_POST_API = "/pactplan/create";

// Add Manager Note
const MYPLANMGRNOTE_ADD_DATA = {
  MYPLANMGRNOTE_ADD_DATA_LOAD: "MYPLANMGRNOTE_ADD_DATA_LOAD",
  MYPLANMGRNOTE_ADD_DATA_SUCCESS: "MYPLANMGRNOTE_ADD_DATA_SUCCESS",
  MYPLANMGRNOTE_ADD_DATA_FAIL: "MYPLANMGRNOTE_ADD_DATA_FAIL",
};
const MYPLANMGRNOTE_POST_API = "/pactplan/addnotes";


// Add Note
const MYPLANNOTE_ADD_DATA = {
  MYPLANNOTE_ADD_DATA_LOAD: "MYPLANNOTE_ADD_DATA_LOAD",
  MYPLANNOTE_ADD_DATA_SUCCESS: "MYPLANNOTE_ADD_DATA_SUCCESS",
  MYPLANNOTE_ADD_DATA_FAIL: "MYPLANNOTE_ADD_DATA_FAIL",
};
const MYPLANNOTE_POST_API = "/pactplan/addnotes";

// delete Note
const MYPLANNOTE_DELETE_DATA = {
  MYPLANNOTE_DELETE_LOAD: "MYPLANNOTE_DELETE_LOAD",
  MYPLANNOTE_DELETE_SUCCESS: "MYPLANNOTE_DELETE_SUCCESS",
  MYPLANNOTE_DELETE_FAIL: "MYPLANNOTE_DELETE_FAIL",
};
const MYPLANNOTE_DELETE_API = "/pactplan/deletenotes";


//Update
const MYPLAN_UPDATE_DATA = {
  MYPLAN_UPDATE_DATA_LOAD: "MYPLAN_UPDATE_DATA_LOAD",
  MYPLAN_UPDATE_DATA_SUCCESS: "MYPLAN_UPDATE_DATA_SUCCESS",
  MYPLAN_UPDATE_DATA_FAIL: "MYPLAN_UPDATE_DATA_FAIL",
};

const MYPLAN_PUT_API = "/pactplan/update";

//Delete
const MYPLAN_DELETE_DATA = {
  MYPLAN_DELETE_DATA_LOAD: "MYPLAN_DELETE_DATA_LOAD",
  MYPLAN_DELETE_DATA_SUCCESS: "MYPLAN_DELETE_DATA_SUCCESS",
  MYPLAN_DELETE_DATA_FAIL: "MYPLAN_DELETE_DATA_FAIL",
};

const MYPLAN_DELETE_API = "/pactplan/myplan";

//Get team plan by id and location id and period
const MYPLAN_EMP_LOC_PER_GET_DATA = {
  MYPLAN_EMP_LOC_PER_GET_DATA_LOAD: "MYPLAN_EMP_LOC_PER_GET_DATA_LOAD",
  MYPLAN_EMP_LOC_PER_GET_DATA_SUCCESS: "MYPLAN_EMP_LOC_PER_GET_DATA_SUCCESS",
  MYPLAN_EMP_LOC_PER_GET_DATA_FAIL: "MYPLAN_EMP_LOC_PER_GET_DATA_FAIL",
};

const MYPLAN_EMP_LOC_PER_GET_DETAIL_API = "/pactplan/getPlanByEmpLocPer/";


//Get team plan by id and location id and period
const PLAN_BY_EMP_GET_DATA = {
  PLAN_BY_EMP_GET_DATA_LOAD: "PLAN_BY_EMP_GET_DATA_LOAD",
  PLAN_BY_EMP_GET_DATA_SUCCESS: "PLAN_BY_EMP_GET_DATA_SUCCESS",
  PLAN_BY_EMP_GET_DATA_FAIL: "PLAN_BY_EMP_GET_DATA_FAIL",
};

const PLAN_BY_EMP_GET_DETAIL_API = "/pactplan/getAllPlanByEmp/";


export {  
  MYPLAN_GET_DATA,
  MYPLAN_GET_DETAIL_API,
  MYPLAN_ADD_DATA,
  MYPLAN_POST_API,
  MYPLAN_UPDATE_DATA,
  MYPLAN_PUT_API,
  MYPLAN_DELETE_DATA,
  MYPLAN_DELETE_API,
  MYPLANNOTE_ADD_DATA,
  MYPLANNOTE_POST_API,
  MYPLANMGRNOTE_ADD_DATA,
  MYPLANMGRNOTE_POST_API,
  MYPLANNOTE_DELETE_DATA,
  MYPLANNOTE_DELETE_API,
  TEAMPLAN_GET_DATA,
  TEAMPLAN_GET_DETAIL_API,
  PLAN_BY_LOC_LEVEL_GET_DATA,
  PLAN_BY_LOC_LEVEL_GET_DETAIL_API,
  MYLOC_GET_DATA,
  MYLOC_GET_DETAIL_API,
  MYPLAN_EMP_LOC_PER_GET_DATA,
  MYPLAN_EMP_LOC_PER_GET_DETAIL_API,
  PLAN_BY_EMP_GET_DATA,
  PLAN_BY_EMP_GET_DETAIL_API
};
