import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DefaultButton from "../../../shared/components/DefaultButton";
import FormikDatePickerField from "../../../shared/components/FormikDatePickerField";
import FormikSelectField from "../../../shared/components/FormikSelectField";
import { loadRegionDataRequest } from "../../region/redux/Actions";
import { CANCEL, DISTRICT_NAME, END_DATE, REGION, SAVE, STARTAFTEREND, STARTNOTFUTURE, START_DATE } from "../../../shared/constants/Constants";
import FormatDate from "../../../shared/components/FormatDate";



const MarketTrackerForm = ({ tracker, submit, cancel, companyId = 0 }) => {
    const dispatch = useDispatch();

    const validate = (values) => {
        const errors = {};
        if (!values.region_id || values.region_id === -1 || values.region_id === '') {
            errors.region_id = 'Required';
        }
        if (!values.start_date || values.start_date === '') {
            errors.start_date = "Required";
        }
        if (!values.end_date || values.end_date === '') {
            errors.end_date = "Required";
        }
        if (new Date(values.start_date) >= new Date(values.end_date)) {
            errors.end_date = STARTAFTEREND
        }
        if (new Date() >= new Date(values.start_date)){
            // errors.start_date = STARTNOTFUTURE
        }

        return errors;
    };

    let [regionData, setRegionData] = useState([])
    let { regions } = useSelector(store => store.region)
    useEffect(() => {
        if (regions.length === 0) {
            dispatch(loadRegionDataRequest());
        }
    }, [dispatch, regions.length])

    useEffect(() => {
        if (companyId > 0) {
            setRegionData([...regions].filter(
                (region) => {
                    //return region.company_id === companyId
		    return region.active === 0 || region.active ===2
                }
            ))
        } else {
            setRegionData([...regions])
        }
    }, [companyId, regions])

    let trackStartDate = '';
    let trackEndDate = '12/31/2099';
    if (tracker && tracker?.start_date && tracker?.end_date) {
        if (tracker?.start_date.includes("-") && tracker?.end_date.includes("-")) {
            trackStartDate = FormatDate(tracker?.start_date);
            trackEndDate = FormatDate(tracker?.end_date); 
        }
    }

    return <Formik
        initialValues={{
            tracker_id: tracker?.id?? '',
            market_name: tracker?.market_name?? '',
            region_id: tracker?.region_id?? '',
            start_date: trackStartDate,
            end_date: trackEndDate
        }}
        validate={validate}
        onSubmit={submit}
        enableReinitialize
    >
        {({ values, setFieldTouched, setFieldValue }) => (
            <Form className="row g-4">
                <div className="col-md-6">
                    <label htmlFor="market_name" className="form-label">
                        {DISTRICT_NAME}
                    </label>
                    <Field disabled={true} className="form-control" type='text' name='market_name' />
                    <span className="error-text">
                        <ErrorMessage name="market_name" />
                    </span>
                </div>
                <div className="col-md-6">
                    <label g="form-label">
                        {REGION} <span className="error-text">*</span>
                    </label>
                    <FormikSelectField
                        name="region_id"
                        value={values.region_id}
                        onChange={setFieldValue}
                        placeholder={'Select Region'}
                    >
                        {regionData.filter(region => region.active === 0 || region.active === 2).map((region) => {
                            return <option key={region.id} value={region.id}>{region.region_name}</option>
                        })}
                    </FormikSelectField>
                    <span className='error-text'>
                        <ErrorMessage name='region_id' />
                    </span>
                </div>
                <div className="col-md-6 datepicker">
                    <label className="form-label">{START_DATE} <span className="error-text">*</span> </label>
                    <FormikDatePickerField name="start_date" value={values.start_date} onChange={setFieldValue} handleBlur={setFieldTouched} />
                    <span className='error-text'>
                        <ErrorMessage name="start_date" />
                    </span>
                </div>
                <div className="col-md-6 datepicker">
                    <label className="form-label">{END_DATE} <span className="error-text">*</span> </label>
                    <FormikDatePickerField name="end_date" value={values.end_date} onChange={setFieldValue} handleBlur={setFieldTouched} />
                    <span className='error-text'>
                        <ErrorMessage name="end_date" />
                    </span>
                </div>
                <Col md={12}>
                    <DefaultButton buttonClass={"btn btn-secondary me-2"} actionBlock={cancel} buttonTitle={CANCEL} />
                    <DefaultButton
                        buttonClass={"default-btn-color"}
                        buttonTitle={SAVE}
                        buttonType={"submit"}
                    />
                </Col>
            </Form>
        )}
    </Formik>
}

export default MarketTrackerForm
