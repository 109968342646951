import React from 'react'
import './Menu.css'
import { NavLink } from 'react-router-dom'
import {MYPACK, TEAMPACK, COMPANY, DIVISION, REGION, MARKET, STORES, EMPLOYEES, ACQUISITION, DENSITY, STORECLASSIFICATION, COMPGROUP, CTYPE, EMPLOYEE_DEPARTMENT, EMPLOYEE_TYPE, EMPLOYEE_LEAVE_TYPE, JOB_TITLE, EMPLOYEE_STATUS, HIRE_STATUS, TERM_REASON, PART_FULL_TIME, HOURLY_SALARY, STOREHOURS, STORETRACKER, DENSITYTRACKER, COMMCOMPTRACKER, STORECLASSIFICATIONTRACKER, DESIGNATION_TRACKER, EMP_TYPE_HIRE_STATUS_TRACKER, ROLES, GROUPS, MODULEMETADATA, ROLE_MODULE_MAPPING, EMPLOYEE_ROLE_MAPPING, ODOO_STORE_TRANSFER_TRACKERS, ODOO_DESIGNATION_TRACKERS, KEY_TAG, STORETABLES, EMPLOYEETABLES } from '../../shared/constants/Constants';
import MenuCollapsableItem from './MenuCollapsableItem'
import { Col } from 'react-bootstrap';
import Permissions from '../../shared/components/Permissions';

function isMenuActive({ isActive }) {
    return isActive ? 'menu-active' : '';
}

const Menu = ({ toggled, toggleMenu }) => {

    let logoPath = "/company-logo.png";
    //let logoPath = "https://wirelessvision.com/wp-content/uploads/2019/05/logo.png";

    let empInfo = JSON.parse(localStorage.getItem('employee'));
    let securityTab = false;
    if (empInfo?.role === "admin") {
        securityTab = true;
    }

    let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    let module_name = "VMO";
    let vmoPermission = Permissions(emp_no, module_name);

    console.log("per::", vmoPermission);

    return (
        <div className={"sidebar-wrapper " + (toggled ? "d-none" : "")}>
            <div className="sidebar-header">
                <Col xs={4}>
                    <img src={logoPath} alt="logo icon" className='company-logo' />
                </Col>
                <Col>
                    <h4 className="logo-text">Wireless Vision</h4>
                </Col>
                <Col xs={1} className="toggle-menu float-end d-block d-md-none" onClick={toggleMenu}>
                    <i className='fa fa-bars'></i>
                </Col>
            </div>
            <div id="menu-spacer" style={{ height: '70px' }}></div>
            <div className="sidebar-body">
                <ul className="metismenu" id="menu">
                    {(empInfo?.role === "hc_sales") ? (<>
                        <li>
                            <NavLink to={'/userProfile'}>
                                <div className="parent-icon"><i className='fa fa-user-o'></i>
                                </div>
                                <div className="menu-title">Profile</div>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={'/t-shirtSize'}>
                                <div className="parent-icon"><i className='fa fa-circle-info'></i>
                                </div>
                                <div className="menu-title">Additional Info</div>
                            </NavLink>
                        </li>
			<li>
                            <NavLink to={'/myplan?filter=none'}>
                                <div className="parent-icon"><i className='fa fa-tasks'></i>
                                </div>
                                <div className="menu-title">{MYPACK}</div>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={'/teamplan?filter=none'}>
                                <div className="parent-icon"><i className='fa fa-group'></i>
                                </div>
                                <div className="menu-title">{TEAMPACK}</div>
                            </NavLink>
                        </li> 
                    </>): (
                    <>
                        <li>
                            <NavLink to={'/dashboard?filter=none'}>
                                <div className="parent-icon"><i className='fa fa-home'></i>
                                </div>
                                <div className="menu-title">Dashboard</div>
                            </NavLink>
                        </li>
                        <MenuCollapsableItem hasRightArrow={true}
                            parent={[
                                <div className="parent-icon"><i className="fa fa-map"></i>
                                </div>,
                                <div style={{ marginLeft: '10px' }} className="menu-title">Org. Hierarchy</div>
                            ]}
                            children={[
                                <li> <NavLink to={'/company?filter=none'} className={isMenuActive}>{COMPANY}</NavLink>
                                </li>,
                                <li> <NavLink to={'/division?status=active'} className={isMenuActive}>{DIVISION}</NavLink>
                                </li>,
                                <li> <NavLink to={'/region?status=active'} className={isMenuActive}>{REGION}</NavLink>
                                </li>,
                                <li> <NavLink to={'/district?filter=none'} className={isMenuActive}>{MARKET}</NavLink>
                                </li>,
                                // <MenuCollapsableItem hasLeftArrow={true} className="padding-left-12"
                                //     parent={[
                                //         <div className="sub-menu-title me-3">{REGION}</div>
                                //     ]}
                                //     children={[
                                //         <li> <NavLink to={'/region?filter=none'} className={isMenuActive}>Region List</NavLink>
                                //         </li>,
                                //         <li> <NavLink to={'/region-tracker?filter=none'} className={isMenuActive}>Region Tracker</NavLink>
                                //         </li>
                                //     ]}
                                // />,
                                // <MenuCollapsableItem hasLeftArrow={true} className="padding-left-12"
                                //     parent={[
                                //         <div className="sub-menu-title me-3">{MARKET}</div>
                                //     ]}
                                //     children={[
                                //         <li> <NavLink to={'/district?filter=none'} className={isMenuActive}>District List</NavLink>
                                //         </li>,
                                //         <li> <NavLink to={'/district-tracker?filter=none'} className={isMenuActive}>District Tracker</NavLink>
                                //         </li>
                                //     ]}
                                // />,
                                <li> <NavLink to={'/stores?filter=none'} className={isMenuActive}>{STORES}</NavLink>
                                </li>,
                                <MenuCollapsableItem hasLeftArrow={true} className="padding-left-12"
                                    parent={[
                                        <div className="sub-menu-title me-3">{STORETABLES}</div>
                                    ]}
                                    children={[
                                        // <li> <NavLink to={'/stores?filter=none'} className={isMenuActive}>{STORES}</NavLink>
                                        // </li>,
                                        <li><NavLink to={'/channel-type?filter=none'} className={isMenuActive}>{CTYPE}</NavLink></li>,
                                        <li> <NavLink to={'/store-classification?filter=none'} className={isMenuActive}>{STORECLASSIFICATION}</NavLink></li>,
                                        <li> <NavLink to={'/density?filter=none'} className={isMenuActive}>{DENSITY}</NavLink></li>,
                                        <li> <NavLink to={'/acquisition?filter=none'} className={isMenuActive}>{ACQUISITION}</NavLink>
                                        </li>,
                                        <li><NavLink to={'/store-hours?filter=none'}>{STOREHOURS}</NavLink></li>,

                                        <li> <NavLink to={'/commission-comp-group?filter=none'} className={isMenuActive}>{COMPGROUP}</NavLink>
                                        </li>,
                                        // <li> <NavLink to={'/store-tracker?filter=none'} className={isMenuActive}>{STORETRACKER}</NavLink>
                                        // </li>,
                                        // <li> <NavLink to={'/densityclass-tracker?filter=none'} className={isMenuActive}>{DENSITYTRACKER}</NavLink>
                                        // </li>,
                                        // <li> <NavLink to={'/commcomp-tracker?filter=none'} className={isMenuActive}>{COMMCOMPTRACKER}</NavLink>
                                        // </li>,
                                        // <li> <NavLink to={'/store-classification-tracker?filter=none'} className={isMenuActive}>{STORECLASSIFICATIONTRACKER}</NavLink>
                                        // </li>,
                                        // <li><NavLink to={'/store-hours?filter=none'}>{STOREHOURS}</NavLink></li>
                                    ]}

                                />
                            ]}
                        />
                        <MenuCollapsableItem hasRightArrow={true}
                            parent={[
                                <div className="parent-icon"><i className="fa fa-users"></i>
                                </div>,
                                <div style={{ marginLeft: '10px' }} className="menu-title">Employees</div>
                            ]}
                            children={[
                                <li> <NavLink to={'/employees?filter=none'} className={isMenuActive}>{EMPLOYEES}</NavLink>
                                </li>,
                                // <li> <NavLink to={'/t-shirtSize?filter=none'} className={isMenuActive}>My Additional Info</NavLink>
                                // </li>,
                                <li> <NavLink to={'/all-tshirtsizes?filter=none'} className={isMenuActive}>Employee Additional Info</NavLink>
                                </li>,
                                <MenuCollapsableItem hasLeftArrow={true} className="padding-left-12"
                                    parent={[
                                        <div className="sub-menu-title me-3">{EMPLOYEETABLES}</div>
                                    ]}
                                    children={[
                                        <li> <NavLink to={'/departments?filter=none'} className={isMenuActive}>{EMPLOYEE_DEPARTMENT}</NavLink>
                                        </li>,
                                        <li> <NavLink to={'/employee-types?filter=none'} className={isMenuActive}>{EMPLOYEE_TYPE}</NavLink>
                                        </li>,
                                        <li> <NavLink to={'/leave-types?filter=none'} className={isMenuActive}>{EMPLOYEE_LEAVE_TYPE}</NavLink>
                                        </li>,
                                        <li> <NavLink to={'/jobtitles?filter=none'} className={isMenuActive}>{JOB_TITLE}</NavLink>
                                        </li>,
                                        <li> <NavLink to={'/employee-status?filter=none'} className={isMenuActive}>{EMPLOYEE_STATUS}</NavLink>
                                        </li>,
                                        <li> <NavLink to={'/hire-status?filter=none'} className={isMenuActive}>{HIRE_STATUS}</NavLink>
                                        </li>,
                                        <li> <NavLink to={'/term-reason?filter=none'} className={isMenuActive}>{TERM_REASON}</NavLink>
                                        </li>,
                                        <li> <NavLink to={'/full-part-time?filter=none'} className={isMenuActive}>{PART_FULL_TIME}</NavLink>
                                        </li>,
                                        <li> <NavLink to={'/hourly-salary?filter=none'} className={isMenuActive}>{HOURLY_SALARY}</NavLink>
                                        </li>,
                                    ]}

                                />,
                                // <li> <NavLink to={'/designation-tracker?filter=none'} className={isMenuActive}>{DESIGNATION_TRACKER}</NavLink>
                                // </li>,
                                // <li> <NavLink to={'/emptype-hirestatus-tracker?filter=none'} className={isMenuActive}>{EMP_TYPE_HIRE_STATUS_TRACKER}</NavLink>
                                // </li>,

                            ]}
                        />
                        {securityTab ? (
                        <MenuCollapsableItem hasRightArrow={true}
                            parent={[
                                <div className="parent-icon"><i className="fas fa-shield"></i>
                                </div>,
                                <div style={{ marginLeft: '10px' }} className="menu-title">Security</div>
                            ]}
                            children={[
                                <li> <NavLink to={'/roles?filter=none'} className={isMenuActive}>{ROLES}</NavLink>
                                </li>,
                                <li> <NavLink to={'/groups?filter=none'} className={isMenuActive}>{GROUPS}</NavLink>
                                </li>,
                                <li> <NavLink to={'/module-meta-data?filter=none'} className={isMenuActive}>{MODULEMETADATA}</NavLink>
                                </li>,
                                <li> <NavLink to={'/role-module-mapping?filter=none'} className={isMenuActive}>{ROLE_MODULE_MAPPING}</NavLink>
                                </li>,
                                <li> <NavLink to={'/employee-role-mapping?filter=none'} className={isMenuActive}>{EMPLOYEE_ROLE_MAPPING}</NavLink>
                                </li>,
                            ]}
                        />
                        ) : (<></>)}

                        {/* {securityTab ? ( */}
                        <MenuCollapsableItem hasRightArrow={true}
                            parent={[
                                <div className="parent-icon"><i className="fas fa-dot-circle"></i>
                                </div>,
                                <div style={{ marginLeft: '10px' }} className="menu-title">Odoo</div>
                            ]}
                            children={[
                                <li> <NavLink to={'/odoo-store-transfer-trackers?filter=none'} className={isMenuActive}>{ODOO_STORE_TRANSFER_TRACKERS}</NavLink>
                                </li>,
                                <li> <NavLink to={'/odoo-designation-trackers?filter=none'} className={isMenuActive}>{ODOO_DESIGNATION_TRACKERS}</NavLink>
                                </li>,
                            ]}
                        />
                        {/* ) : (<></>)} */}

                        {/* {securityTab ? ( */}
                        <li>
                            <NavLink to={'/admin-key-tag?filter=none'}>
                                <div className="parent-icon"><i className='fas fa-tags'></i>
                                </div>
                                <div className="menu-title">{KEY_TAG}</div>
                            </NavLink>
                        </li>
                        {/* ) : (<></>)}                    
                        {securityTab ? ( */}
                        <MenuCollapsableItem hasRightArrow={true}
                            parent={[
                                <div className="parent-icon"><i className="fa fa-history"></i>  
                                </div>,
                                <div style={{ marginLeft: '10px' }} className="menu-title">Trackers</div>
                            ]}
                            children={[
                                <li> <NavLink to={'/region-tracker?filter=none'} className={isMenuActive}>Region Tracker</NavLink>
                                </li>,
                                <li> <NavLink to={'/district-tracker?filter=none'} className={isMenuActive}>District Tracker</NavLink>
                                </li>,
                                <li> <NavLink to={'/store-tracker?filter=none'} className={isMenuActive}>{STORETRACKER}</NavLink>
                                </li>,
                                <li> <NavLink to={'/densityclass-tracker?filter=none'} className={isMenuActive}>{DENSITYTRACKER}</NavLink>
                                </li>,
                                <li> <NavLink to={'/commcomp-tracker?filter=none'} className={isMenuActive}>{COMMCOMPTRACKER}</NavLink>
                                </li>,
                                <li> <NavLink to={'/store-classification-tracker?filter=none'} className={isMenuActive}>{STORECLASSIFICATIONTRACKER}</NavLink>
                                </li>,
                                <li> <NavLink to={'/designation-tracker?filter=none'} className={isMenuActive}>{DESIGNATION_TRACKER}</NavLink>
                                </li>,
                                <li> <NavLink to={'/emptype-hirestatus-tracker?filter=none'} className={isMenuActive}>{EMP_TYPE_HIRE_STATUS_TRACKER}</NavLink>
                                </li>,

                            ]}
                        />
                        {/* ) : (<></>)}
                        {securityTab ? ( */}
                        <MenuCollapsableItem hasRightArrow={true}
                            parent={[
                                <div className="parent-icon"><i className="fa fa-clipboard-list"></i>
                                </div>,
                                <div style={{ marginLeft: '10px' }} className="menu-title">Audits</div>
                            ]}
                            children={[
                                <li> <NavLink to={'/queue-table?filter=none'} className={isMenuActive}>Workflows Queue</NavLink>
                                </li>,
                                <li> <NavLink to={'/audit?filter=none'} className={isMenuActive}>Audit logs</NavLink>
                                </li>,
                                <li> <NavLink to={'/hireTermQueue?filter=none'} className={isMenuActive}>Hire Term Queue</NavLink> </li>,

                            ]}
                        />
                        <MenuCollapsableItem hasRightArrow={true}
                            parent={[
                                <div className="parent-icon"><i className="fa fa-shopping-cart"></i>
                                </div>,
                                <div style={{ marginLeft: '10px' }} className="menu-title">Product</div>
                            ]}
                            children={[
                                <li> <NavLink to={'/products'} className={isMenuActive}>Products</NavLink>
                                </li>,
                                <li> <NavLink to={'/product-tables?filter=none'} className={isMenuActive}>Product Tables</NavLink>
                                </li>,
                                // <MenuCollapsableItem hasLeftArrow={true} className="padding-left-12"
                                //     parent={[
                                //         <div className="sub-menu-title me-3">Product Tables</div>
                                //     ]}
                                //     children={[
                                //         <li> <NavLink to={'/product-type?filter=none'} className={isMenuActive}>Product Type</NavLink>
                                //         </li>,
                                //         <li> <NavLink to={'/accessories?filter=none'} className={isMenuActive}>Accessories</NavLink>
                                //         </li>,
                                //         <li> <NavLink to={'/account-type?filter=none'} className={isMenuActive}>Account Type</NavLink>
                                //         </li>,
                                //         <li> <NavLink to={'/category?filter=none'} className={isMenuActive}>Category</NavLink>
                                //         </li>,
                                //         <li> <NavLink to={'/category-product?filter=none'} className={isMenuActive}>Category Product</NavLink>
                                //         </li>,
                                //         <li> <NavLink to={'/line-type?filter=none'} className={isMenuActive}>Line Type</NavLink>
                                //         </li>,
                                //         <li> <NavLink to={'/relationship-type?filter=none'} className={isMenuActive}>Relationship Type</NavLink>
                                //         </li>,
                                //     ]}

                                // />,

                            ]}
                        />
                        {vmoPermission.write_permission ? (<>
                        <MenuCollapsableItem hasRightArrow={true}
                            parent={[
                                <div className="parent-icon"><i className="fa fa-pencil-square-o"></i>
                                </div>,
                                <div style={{ marginLeft: '10px' }} className="menu-title">VMO</div>
                            ]}
                            children={[
                                <li> <NavLink to={'/quarterlyReview?filter=none'} className={isMenuActive}>Quaterly Review</NavLink>
                                </li>,
                                <li> <NavLink to={'/retail-foundation?filter=none'} className={isMenuActive}> Retail Foundation</NavLink>
                                </li>,
                            ]}
                        /></>) : (<></>)}
			<li>
                            <NavLink to={'/allplan?filter=none'}>
                                <div className="parent-icon"><i className='fa fa-file'></i>
                                </div>
                                <div className="menu-title">{"All Priority Plans"}</div>
                            </NavLink>
                        </li>                      

                        <></>
                    </>)}
                    {/* ) : (<></>)} */}
                </ul>
            </div>
        </div>
    )
}

export default Menu
