export const API_URL = 'https://mdsapi.wirelessvision.com'
//export const API_URL = 'http://localhost:3001'
export const BASE_URL  = 'http://localhost:3000'
export const LOGIN_BASE_URL = 'https://0sylzdpaf7.execute-api.us-east-1.amazonaws.com'

//https://mdstest.wirelessvision.com/

//
export const DASHBOARD = 'Dashboard'

//Common Translations
export const CREATENEW = 'Create New'

export const CANCEL = 'Cancel'
export const SAVE = 'Save'
export const DELETE = 'Delete '
export const EXPORT = 'Export'
export const IMPORT = 'Import'
export const BACKTOLIST = 'Back to List'
export const EDIT = 'Edit '
export const ADD = 'Add '
export const ADDITEM = 'Add an Item'
export const BACKTODETAILS = 'Back to Details'
export const START_DATE = 'Start Date'
export const END_DATE = 'End Date'
export const DELETE_CONFIRM = 'Are you sure you want to Delete?'
export const ALL_COMPANIES = 'All Companies'
export const TRACKER = 'TRACKER '
export const CREATE = 'Create '

// Company Related Translations
export const MYPACK = 'My Pack Plan'
export const TEAMPACK = 'Team Pack Plan'



// Company Related Translations
export const COMPANY = 'Company'
export const IMPORTCOMPANY = 'Import Company'
export const UDATE_COMPANY = 'Update Company'
export const CREATECOMPANY = 'Create Company'
export const COMPANY_NAME = 'Company Name'
export const ULTI_COMPANY_CODE = 'Ulti Company Code'
export const MASTER_DEALER_CODE = 'Master Dealer Code'


// Division Related Translations
export const DIVISION = 'Division'
export const IMPORTDIVISION = 'Import Division'
export const CREATEDIVISION = 'Create Division'
export const DIVISION_NAME = 'Division Name'
export const ULTI_DIVISION_CODE = 'Ulti Division Code'
export const DELETE_DIVISION = 'Delete Division'

// Region Related Translations
export const REGION = 'Region'
export const REGIONTRACKER = 'Region Tracker'
export const IMPORTREGION = 'Import Region'
export const CREATEREGION = 'Create Region'
export const REGION_NAME = 'Region Name'
export const ULTI_REGION_CODE = 'Ulti Region Code'
export const REGION_TRACKER = 'Region Tracker'
export const DELETE_REGION = 'Delete Region'


// Market Related Translations
export const MARKET = 'District'
export const MARKETTRACKER = "District Tracker"
export const IMPORTMARKET = "Import District"
export const DISTRICT_NAME = 'District Name'
export const ULTI_DISTRICT_CODE = 'Ulti District Code'
export const DISTRICT_TRACKER = 'District Tracker'
export const IMPORT_DISTRICT_TRACKER = 'Import District Tracker'

// Stores Related Translations
export const STORES = 'Stores'
export const ACQUISITION = 'Acquisition Dealer'
export const DENSITY = 'Density Class'
export const STORECLASSIFICATION = 'Store Classification'
export const COMPGROUP = 'Commission Comp Group'
export const CTYPE = 'Channel Type'
export const CHANNEL_NAME = 'Channel Name'
export const STOREHOURS = 'Store Hours'
export const STORETRACKER = 'SAP Tracker'
export const IMPORTSTORETRACKER = 'Import Store Tracker'
export const STORE_CLASSIFICATION_NAME = 'Store Classification Name'
export const TRAFFIC_REDUCTION = 'Traffic Reduction'
export const ACQUISITION_DEALER_NAME  = 'Acquisition Dealer Name'
export const ACQUISITION_DATE = 'Acquisition Date'
export const COMM_GROUP_NAME = 'Commission Comp Group Name'

export const DENSITYTRACKER = 'Density Class Tracker'
export const IMPORT_DENSITYTRACKER = 'Import Density Class Tracker'
export const DENSITY_CLASS_NAME = 'Density Class Name'

export const COMMCOMPTRACKER = 'Comm Comp Tracker'
export const IMPORT_COMMCOMPTRACKER = 'Import Comm Comp Tracker'

export const STORECLASSIFICATIONTRACKER = 'Store Classification Tracker'
export const IMPORT_STORE_CLASSIFICATION_TRACKER = 'Import Store Classification Tracker'

export const STORETABLES = 'Store Tables'
export const EMPLOYEETABLES = 'Employee Tables'

// Employee Related Translations
export const EMPLOYEES = 'Employees'
export const EMPLOYEE_DEPARTMENT = 'Department'
export const EMPLOYEE_LEAVE_TYPE = "Leave Type"
export const EMPLOYEE_TYPE = "Employee Type"
export const JOB_TITLE = 'Job Title'
export const IMPORT_JOBTITLE = "Import Job Titles"
export const ULTI_JOB_CODE = "Ulti Job Title"
export const EMPLOYEE_STATUS = 'Employee Status'
export const HIRE_STATUS = 'Hire Status'
export const TRAINING_DAYS = "Training Days"
export const TERM_REASON = "Term Reason"
export const PART_FULL_TIME = "Part Full Time Status"
export const HOURLY_SALARY = "Hourly Salary Status"
export const HOURS_PER_WEEK = "Hours Per Week"
export const ULTI_LEAVE_CODE = 'Ulti Leave Code'
export const STORE = 'Store'
export const DESIGNATION_TRACKER = 'Designation Tracker'
export const EMP_TYPE_HIRE_STATUS_TRACKER = 'Employee Type Hire Status Tracker'

export const QUEUE_TABLE = "Queue Table"
export const QUATERLY = "Quaterly"

//Form Date Errors
export const STARTAFTEREND = "Cannot end before start date"
export const STARTNOTFUTURE = "Start date must be after today"
export const OPENDATEFUTURE = "Open Date must be after acquisition date"

//Security
export const ROLES = "Roles"
export const GROUPS = "Groups"
export const MODULEMETADATA = "Module Meta Data"
export const ROLE_MODULE_MAPPING = "Role Module Mapping"
export const EMPLOYEE_ROLE_MAPPING = "Employee Role Mapping"

//Odoo
export const ODOO_STORE_TRANSFER_TRACKERS = "Store Transfer Trackers"
export const ODOO_DESIGNATION_TRACKERS = "Odoo Designation Trackers"

//Tagging
export const KEY_TAG = "Admin Key Tag"

//Dynamic Form
export const FORM_BULK_IMPORT = "Form Bulk Import"
