import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import { useEffect, useState, useRef  } from "react";
import { Card,Button,Collapse,  Col, Row } from "react-bootstrap";
import Breadcrumbs from "../../../shared/components/Breadcrumbs"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate , useParams,useLocation   } from "react-router-dom";
import DefaultButton from "../../../shared/components/DefaultButton";
import FormikDatePickerField from "../../../shared/components/FormikDatePickerField";
import FormikSelectField from "../../../shared/components/FormikSelectField";
import FormTextField from "../../../shared/components/FormTextField";
import FormTextAreaField from "../../../shared/components/FormTextAreaField";
import DisabledTextField from "../../../shared/components/DisabledTextField";
import { loadMyPlanAddDataRequest,  loadMyPlanGetDataRequest,loadAllPlanByEmpGetDataRequest, loadMyPlanNoteAddDataRequest,
    loadMyPlanNoteDeleteDataRequest, loadPlanByEmpLocPerGetDataRequest } from "../redux/Actions"
import ListView from "../../../shared/components/listview/ListView"
import Modalpopup from "../../../shared/components/Modalpopup"
import FormError from "../../../shared/components/FormError"
import MyPlanView from "./MyPlanView"
import MyPlanNotesEdit from "./MyPlanNotesEdit"
import { DELETE, CANCEL, DELETE_CONFIRM } from "../../../shared/constants/Constants"
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import GeneratePDF from "./GeneratePDF";

import Loader from '../../../shared/components/Loader';
//import { CANCEL, DIVISION, EMPLOYEE_DEPARTMENT, EMPLOYEE_STATUS, HIRE_STATUS, HOURLY_SALARY, JOB_TITLE, MARKET, PART_FULL_TIME, REGION, SAVE, STORE } from "../../../shared/constants/Constants";

const MyPlanForm = () => {
    const location = useLocation();
    const { row } = location.state || {};
    console.log("select row",row )
    let params = useParams();
    const dispatch = useDispatch();
    const formik = useFormik({});  

    const getQuarter = (date) => {
        const month = date.getMonth() + 1; 
        if (month >= 1 && month <= 3) {
          return 'Q1';
        } else if (month >= 4 && month <= 6) {
          return 'Q2';
        } else if (month >= 7 && month <= 9) {
          return 'Q3';
        } else {
          return 'Q4';
        }
      };
      let currentYear = new Date().getFullYear();
      let currentQuarter = getQuarter(new Date());

    let [clickViewFunc, setClickViewFunc] = useState(null);
    let [clickDownloadFunc, setClickDownloadFunc] = useState(null); 
    let [selectedPlan, setSelectedPlan] = useState({});
    let [selectedDownloadPlan, setSelectedDownloadPlan] = useState({});
    let [downloadBlock, setDownloadBlock] =  useState(false);
    let [showEditModal, setShowEditModal] = useState(false)
    let [errorMessage, setErrorMessage] = useState(false);
    let [shouldCloseModal, setShouldCloseModal] = useState(false);
    let [selectedYear, setSelectedYear] =  useState(currentYear);
    let [selectedQuarter, setSelectedQuarter] =  useState(currentQuarter);
    const selectedDownloadPlanRef = useRef(null);    
    const [isDownload, setIsDownload] = useState(true);
    let loc_id =  params.id;

    let [editnotes, setEditnotes] = useState({});
    let [showNotesEditModal, setShowNotesEditModal] = useState(false)
    let[showNotesDeleteModal, setShowNotesDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    let [updateNotesReq, setUpdateNotesReq] = useState({});

      const columnDefs = [
        {
            display_name: "id",
            field: "id",
            type: "number",
            hide: true,
            pinned:'left',
        },
        {
            display_name: (row['location_type'] == 'region') ?  'Region' :  "Market",
            field: "location_name",
            type: "text",
        } ,
        {
            display_name: "Period",
            field: "period",
            hide: true,
            type: "text",
        } ,  
        {
            display_name: "year",
            field: "year",
            hide: true,
            type: "text",
        } ,  
        {
            display_name: "Period Name",
            field: "period_name",
            type: "text",
        } ,        
        {
            display_name: 'Actions',
            field: 'actions',
            hide: false
        },
    ]


    const [initialValues, setInitialValues] = useState({
        employee_id : '',
        location_id : '',
        priority_1: {
          id:'',
          business_priority: '',
          KPI_to_measure: '',
          current_state: '',
          my_actions: '',
          team_actions: '',
          today_notes: '',
          notes : {}
        },
        priority_2: {
          id:'',
          business_priority: '',
          KPI_to_measure: '',
          current_state: '',
          my_actions: '',
          team_actions: '',
          today_notes: '',
          notes : {}
        },
        priority_3: {
          id:'',
          business_priority: '',
          KPI_to_measure: '',
          current_state: '',
          my_actions: '',
          team_actions: '',
          today_notes: '',
          notes : {}
        }
      });

    let empInfo = JSON.parse(localStorage.getItem("employee"));
    
    let { myPlans, myplan,allplans,newnote, error, loading, isSave, getLoad} = useSelector((state) => state.myPlans);
    
    const [collapse, setCollapse] = useState({
        priority_1: true,
        priority_2: false,
        priority_3: false
      });

      useEffect(() => {
        if(isSave) {
            console.log("isSave reinitialize the data");
        }
      }, [myplan, isSave]);
    
      const toggle = (section) => {
        setCollapse((prevState) => ({
          ...prevState,
          [section]: !prevState[section]
        }));
        setDownloadBlock(false);
      };

      useEffect(() => {
        console.log("loading loadFormGetDataRequest")
          dispatch(loadMyPlanGetDataRequest(row));   
          dispatch(loadAllPlanByEmpGetDataRequest(empInfo["emp_no"]));   
         // setInitialValues(myplan)  
      }, [dispatch]);

    const closeEditModal = () => {
        setShowEditModal(false);
        setErrorMessage(null);
    }

    const handleEdit = (values) => {
        //dispatch(loadEmployeeTypeTrackerEditDataRequest(values))
        setShouldCloseModal(true);
    }
    
    
      useEffect(() => {        
        setClickViewFunc(
            () => (row) => {
                console.log("row", row)
                setSelectedPlan(row);
        
                setDownloadBlock(false);
                setShowEditModal(true);
            })   
    }, [])

    useEffect(() => {
        setClickDownloadFunc(
            () => async (row) => {
                setDownloadBlock(true);
                setSelectedDownloadPlan(row);
                selectedDownloadPlanRef.current = row;
                let request = {
                    "employee_id": row['employee_id'],
                    "location_id": row['loc_id'],
                    "period": selectedQuarter,
                    "year": selectedYear
                }
              dispatch(loadPlanByEmpLocPerGetDataRequest(request));
              setDownloadBlock(true);
              
                
            });
                
    }, [dispatch, selectedQuarter, selectedYear]);


    // const validate = (values) => {
    //     const errors = {};
    //     if (!values.employee_id) {
    //         errors.employee_id = "Required";
    //     }
    //     if (new Date(values.hire_date) > new Date(values.term_date)) {
    //         errors.term_date = "Termination date should not be earlier than the hire date."
    //     }
    //     return errors;
    // };
    
    const cancelPlan = (values, priority) => {   
        dispatch(loadMyPlanGetDataRequest(loc_id));   
        //window.location.reload();   
    }
    
    console.log("localStorage",localStorage)
    useEffect(() => {
        if (Object.keys(myplan).length > 0 && getLoad) {
          setInitialValues({
            employee_id :  myplan?.employee_id,
            location_id :  myplan?.location_id,
            priority_1: {
              id : myplan?.priority_1?.id,
              business_priority: myplan?.priority_1?.business_priority,
              KPI_to_measure: myplan?.priority_1?.KPI_to_measure ,
              current_state: myplan?.priority_1?.current_state ,
              my_actions: myplan?.priority_1?.my_actions,
              team_actions: myplan?.priority_1?.team_actions ,
              today_notes: myplan?.priority_1?.today_notes ,
              today_notes_id: myplan?.priority_1?.today_notes_id ,
              notes : myplan?.priority_1?.notes 
            },
            priority_2: {
              id : myplan?.priority_2?.id,
              business_priority: myplan?.priority_2?.business_priority ,
              KPI_to_measure: myplan?.priority_2?.KPI_to_measure ,
              current_state: myplan?.priority_2?.current_state ,
              my_actions: myplan?.priority_2?.my_actions ,
              team_actions: myplan?.priority_2?.team_actions ,
              today_notes: myplan?.priority_2?.today_notes ,
              today_notes_id: myplan?.priority_2?.today_notes_id ,
              notes : myplan?.priority_2?.notes 
            },
            priority_3: {
              id : myplan?.priority_3?.id,
              business_priority: myplan?.priority_3?.business_priority,
              KPI_to_measure: myplan?.priority_3?.KPI_to_measure ,
              current_state: myplan?.priority_3?.current_state ,
              my_actions: myplan?.priority_3?.my_actions ,
              team_actions: myplan?.priority_3?.team_actions ,
              today_notes: myplan?.priority_3?.today_notes ,
              today_notes_id: myplan?.priority_3?.today_notes_id ,
              notes : myplan?.priority_3?.notes 
            }
          });
          console.log("isSave reintializing the value", getLoad, isSave, initialValues)
        }
      }, [myplan]);
    // auto save 
    useEffect(() => {
        const intervalId = setInterval(() => {
        //     Object.entries(['priority_1', 'priority_2', 'priority_3']).forEach(([key, priority])  => {
        //         console.log("Auto save", priority)
        //         saveMyPlan(initialValues, priority, true);
        //     });
            // ['priority_1', 'priority_2', 'priority_3'].map((priority, index) => (
            // ))}
            // initialValues.forEach(({ priority }) => { 
            //     console.log("Auto save", priority)
            // });
            // if (validateForm()) {
            //     saveFormData();
            // } else {
            //     console.log('Form is incomplete, auto-save skipped');
            // }
        }, 1000000); // Auto-save every 5 seconds

        return () => clearInterval(intervalId); 
 
    }, [initialValues]);

    // const handleBlur = (event) => {
    //     // console.log(`Textarea with ID ${event.target.name} lost focus!`);
    //     // console.log(`Current value: ${event.target.value}`);
    //     const name = event.target.name.split('.');
    //     saveMyPlan(initialValues, name[0], true);
    // };

    const customHandleBlur = (event, handleBlur) => {
        handleBlur(event); // Call Formik's handleBlur
        const name = event.target.name.split('.');
        if(initialValues[name[0]][name[1]] != event.target.value ) {
            initialValues[name[0]][name[1]] = event.target.value;
            saveMyPlan(initialValues, name[0], true); // Call your custom logic
            console.log("calling the api")
        } else {
            console.log("Not calling the api")
        }        
        console.log(`Textarea with ID ${event.target.name} lost focus!`);
        console.log(`Current value: ${event.target.value}`);
    };

    const handleTextareaChange = (event,handleBlur, note_id,priority_id) => {
        console.log("handleTextareaChange", note_id)  
        console.log("handleTextareaChange", priority_id)  
        console.log("handleTextareaChange", event.target.value) 
        const name = event.target.name.split('.');
        if(initialValues[name[0]]['today_notes'] != event.target.value ) {
            let requestValue = {
                'note' : event.target.value,
                'id' : note_id, 
                'business_priority_id': priority_id,
                'formInfo' : row    
            }
            console.log("newnote", newnote)
           dispatch(loadMyPlanNoteAddDataRequest(requestValue))
        }
    }
    // const handleNotesEdit = (note_id) => {   
    //     console.log("new click", note_id);  
    // }
   
    const saveMyPlan = (values, priority, isAuto) => {  
        console.log("isSave and get load", getLoad, isSave);  
        console.log("isSave save value", values);  
        let requestValue = {
            employee_id :  empInfo["emp_no"],
            location_id : loc_id           
        }    
        requestValue['isAuto']   = isAuto
        requestValue['priority'] = values[priority]  
        requestValue['selectedForm'] = row;
        //console.log("isSave", isSave, loading);
        dispatch(loadMyPlanAddDataRequest(requestValue))
        //console.log("isSave", isSave, loading);
        //window.location.reload();  
    }

    const handleNotesDelete = (note_id, priority_id) => {
        setShowNotesDeleteModal(true);
        const deleteId = `${note_id}_${priority_id}`;
        setDeleteId(deleteId);  
        console.log("combined Id", deleteId);         
    }

    const confirmDelete = (note_id,priority_id) => {
        let deleterequest = {}
        deleterequest['id'] = note_id
        deleterequest['business_priority_id'] = priority_id
        deleterequest['formInfo'] = row
        dispatch(loadMyPlanNoteDeleteDataRequest(deleterequest))
    };

    const updateNotes = (updaterequest) => {        
        console.log("update note", updaterequest)
        setUpdateNotesReq(updaterequest)
        dispatch(loadMyPlanNoteAddDataRequest(updaterequest))
        setShowNotesEditModal(false);
    }
    
    const handleNotesEdit = (note_id, value, priority, priority_id) => {   
        console.log("new click", note_id);  
        //setShouldCloseModal(true);
        let notes = {};
        notes['id'] = note_id
        notes['note'] = value    
        notes['business_priority_id'] = priority_id
        notes['formInfo'] = row
        setEditnotes(notes)
        setShowNotesEditModal(true);
    }
    
    const closeNotesEditModal = () => {
        setShowNotesEditModal(false);
        setErrorMessage(null);
    }

    console.log("isSave initialValues", initialValues)
    useEffect(() => {
        if (isSave) {
          setInitialValues((prevValues) => ({
            ...prevValues,
            priority_1: {
              ...prevValues.priority_1,
              ...myplan[0].priorities.priority_1,
            },
            priority_2: {
              ...prevValues.priority_2,
              ...myplan[0].priorities.priority_2,
            },
            priority_3: {
              ...prevValues.priority_3,
              ...myplan[0].priorities.priority_3,
            }
          }));
        }
      }, [isSave, myplan]);
      console.log("empInfo", empInfo)
    return (<div> 
    <Breadcrumbs
        children={[
            { href: '/myplan', label: 'My Plan' },
            { href: '/myplan/', label:  (empInfo?.job_title_name == 'Area Vice President') ? (empInfo?.region_name ?? "") : (empInfo?.market_name ?? "") },
        ]}
    />    
    <Formik
        innerRef={formik}
        initialValues= { initialValues  }
        onSubmit={(values, actions) => {
            console.log("Form submitted with values", values);
            //saveMyPlan(values);
            actions.setSubmitting(false); // Ensure to manually set submitting state if needed
          }}
        enableReinitialize  ={true}
    >
        {({ values, handleBlur, setFieldTouched, setFieldValue, validateForm, handleChange  }) => (
            <Form className="row g-4">
                {/* Header */}
                {/* <div><pre>{JSON.stringify(initialValues, null, 2) }</pre></div>
                <div><pre>{JSON.stringify(values, null, 2) }</pre></div> */}
                <Card className="formPage" style={{ backgroundColor: "#d9dfeb", border: '0px',  color: '#0a7674' }}>
                    <Card.Body style={{ padding: "0.25%" }}>
                        <Row >                            
                            <div className="row" style={{ fontSize: '18px' }} >                                
                                <div className="col-6" >
                                <label  htmlFor={"location"} className="form-label text-end">
                                      {"Location:"}  {row['location_name']} 
                                    </label>
                                    
                                </div>
                                <div className="col-6 text-end pr-5" >
                                    <label  htmlFor={"Manager"} className="form-label">
                                       {"Period:"}  { row['period'] } - { new Date().getFullYear() }
                                    </label>
                                </div>
                            </div>                           
                        </Row>
                    </Card.Body>
                </Card>
                {/* loop the priority to create three Section */}
                {['priority_1', 'priority_2', 'priority_3'].map((priority, index) => (
                    <Card className="formPage">
                        <Card.Header>
                            <h5 className="mb-0">
                                <Button                               
                                    className={`px-0 fa ${collapse[priority] ? 'fa-angle-double-down arrow' : 'fa-angle-double-right arrow'}`}                                    
                                    variant="link"
                                    onClick={() => toggle(priority)}
                                    aria-controls="collapseOne"  
                                    style={{ color: '#000', textDecoration: 'none', fontSize: '18px', color: '#fa8c29'}}                                  
                                >
                                    <span className="p-2" style={{ fontFamily: 'Roboto, sans-serif',    fontWeight: '200',    'color': '#000'}}> 
                                   Business Priority  { index+1 }   
                                   {values[priority]?.business_priority !== undefined &&  <span> { '-'} {values[priority]?.business_priority} </span> }  </span>
                                    
                                </Button>
                            </h5>
                        </Card.Header>
                        <Collapse in={collapse[priority]}>
                            <div id="collapseOne" aria-labelledby="headingOne">
                                <Card.Body>
                                    <Row>
                                        <FormTextAreaField name={`${priority}.business_priority`}  label="Business Priority" required 
                                         onBlur={event => {                                           
                                            customHandleBlur(event, handleBlur); 
                                        }} />
                                    </Row>
                                    <Row>
                                        <FormTextAreaField name={`${priority}.KPI_to_measure`}   label="KPI To Measure" required
                                        onBlur={event => {                                           
                                            customHandleBlur(event, handleBlur); 
                                        }}  />
                                    </Row>
                                    <Row>
                                        <FormTextAreaField name={`${priority}.current_state`}   label="Current State" required 
                                        onBlur={event => {                                           
                                            customHandleBlur(event, handleBlur); 
                                        }}/>
                                    </Row>
                                    <Row>
                                        <FormTextAreaField name={`${priority}.my_actions`}   label="My Actions" required
                                        onBlur={event => {                                           
                                            customHandleBlur(event, handleBlur); 
                                        }} />
                                    </Row>
                                    <Row>
                                        <FormTextAreaField name={`${priority}.team_actions`} label="Team Actions" required 
                                        onBlur={event => {                                           
                                            customHandleBlur(event, handleBlur); 
                                        }}/>
                                    </Row>
                                    {/* <Row>
                                        <FormTextAreaField name={`${priority}.current_update`}  label="Current Update" required />
                                    </Row> */}
                                    <Row>
                                        <FormTextAreaField name={`${priority}.today_notes`}  label="Notes" placeholder="Please enter the notes" 
                                        onBlur={event => {                                           
                                            handleTextareaChange(event, handleBlur, values[priority]?.today_notes_id, values[priority]?.id ); 
                                        }}/>

                                        {/* onChangefn={handleTextareaChange} 
                                        />  */}
                                        {/**  <textarea
                                            value={values[priority]?.today_notes}
                                            onChange={handleTextareaChange}
                                            rows={5}  // Adjust rows as needed
                                            cols={50} // Adjust cols as needed
                                        /> */}
                                     {  /* <Field
                                            as="textarea"
                                            name={`${priority}.today_notes`}  
                                            value={values[priority]?.today_notes}
                                            onChange={(e) => {
                                                handleChange(e); 
                                                console.log("notes values[priority]",values[priority])
                                                handleTextareaChange(e, values[priority]?.today_notes_id, values[priority]?.id  ); 
                                              }}
                                            rows={5}
                                            cols={50}
                                        /> */ }
                                    </Row>
                                    <Row> 
                                        <ul className="px-4" style={{ listStyleType: 'disc', color: 'red' }}>
                                            {values[priority]?.notes && Array.isArray(values[priority].notes) && values[priority].notes.map((noteObj, idx) => {                                                
                                                const [date, note] = Object.entries(noteObj)[0];                                                 
                                                return (
                                                    <li style={{ color: 'black' }} className="p-1" key={idx} id={"notes_" + noteObj['notes_id'] }>
                                                        <span style={{ color: 'black', fontWeight:'600' }}> {`${noteObj['emp_name']} - `}  </span>   
                                                        <span style={{ color: 'black', fontWeight:'600' }}> {`${date}: `}  </span>      
                                                        <span > {`${note} `}  </span>   
                                                        { noteObj['employee_id'] == empInfo["emp_no"] ? ( <>
                                                            <span class="m-2" onClick={() => handleNotesEdit(noteObj['notes_id'], note, priority, values[priority]['id'] )} 
                                                                style={{ color: '#0a7674', cursor: 'pointer' }} >  <i class="fas fa-edit" aria-hidden="true"></i>  </span>
                                                            <span class="m-2"  onClick={() => handleNotesDelete(noteObj['notes_id'], values[priority]['id'] )} style={{ color: '#d62728', cursor: 'pointer'  }} >
                                                                <i class="fas fa-trash" aria-hidden="true"></i>
                                                            </span>  </>
                                                         ) : (  <p> </p> )}
                                                        
                                                        {/* <pre>{JSON.stringify(noteObjtest, null, 2)}</pre> */}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </Row>
                                    <Row> 
                                        <Col md={12} className='mt-3'>
                                            <DefaultButton
                                                buttonClass={"btn btn-secondary me-2"}
                                                buttonTitle={"Cancel"} 
                                                buttonType={"reset"}
                                                // actionBlock={() => {
                                                //     cancelPlan(values, priority);
                                                // }} 
                                            />
                                            {/* <button type="button" class="btn btn-primary mb-3" onClick={saveMyPlan}>
                                                Submit
                                            </button> */}
                                            <DefaultButton
                                                buttonClass={"btn btn-secondary default-btn-color"}
                                                buttonTitle={"Save"}
                                                buttonType={"submit"}
                                                actionBlock={() => {
                                                    saveMyPlan(values, priority, false);
                                                }}                                               
                                            
                                            />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </div>
                        </Collapse>
                    </Card>
                ))}
                            
            </Form>
        )}
    </Formik >
     
    {isDownload && <Loader/>}                                         
    <div className="my-4"> 
        <Card className="formPage" style={{ backgroundColor: "#d9dfeb", border: '0px',  color: '#0a7674' }}>
            <Card.Body style={{ padding: "0.25%" }}>
                <Row >                            
                    <div className="row" style={{ fontSize: '18px' }} >                                
                        <div className="col-6" >
                        <label  htmlFor={"location"} className="form-label text-end">
                            History
                        </label>
                        </div>
                       
                    </div>                           
                </Row>
            </Card.Body>
        </Card>
        <div className="my-4">
            {/* <div><pre>{JSON.stringify(allplans, null, 2) }</pre></div>;  */}
            
            <ListView            
                columnNames={columnDefs}
                rowData={allplans}  
                clickView={clickViewFunc}   
                clickDownload={clickDownloadFunc}  
                pact = {true}
            />
            <Modalpopup show={showEditModal} actionTitle="View Plan" handleClose={closeEditModal} size={"lg"}>
                <FormError>
                    {errorMessage}
                </FormError>
            <MyPlanView  submit={handleEdit}cancel={closeEditModal} plan={selectedPlan} period={'Q3'} year = {'2024'} view = {'history'} />
            </Modalpopup>

            <Modalpopup show={showNotesEditModal} actionTitle="Edit Notes" handleClose={closeNotesEditModal} size={"lg"}>
                <FormError>
                    {errorMessage}
                </FormError>
                <MyPlanNotesEdit  submit={updateNotes} cancel={closeNotesEditModal} editnotes={editnotes} />
            </Modalpopup>
            <Modalpopup show={showNotesDeleteModal} actionTitle="Delete Notes" handleClose={() => { setShowNotesDeleteModal(false) }}>
            {DELETE_CONFIRM}
            <div className="col-md-12">
                <DefaultButton
                    buttonClass={"btn btn-secondary me-2"}
                    buttonTitle={CANCEL}
                    actionBlock={() => setShowNotesDeleteModal(false)}
                />
                <DefaultButton
                    buttonClass={"btn btn-danger"}
                    buttonTitle={DELETE}
                    actionBlock={() => {
                        const [note_id, priority_id] = deleteId.split('_');
                        confirmDelete(note_id, priority_id); 
                        setShowNotesDeleteModal(false);
                    }}
                />
            </div>
            </Modalpopup>

           {/**  <div id='download-content' style={{ padding:'40px', fontSize:'2rem' , display: downloadBlock ? 'block' : 'none' }} >
                <div >
                    <MyPlanView plan={selectedDownloadPlan} period={'Q3'} year = {'2024'} view = {'history'}/>
                </div>
            </div>*/}
            <div id="generate-pdf-btn">
            <GeneratePDF plans={selectedDownloadPlan ? [selectedDownloadPlan] : []} period={selectedQuarter} year = {selectedYear} view='download' isDownload= {downloadBlock}  />
            </div>
            
        </div>

        </div>
    </div>
    
    );
}

export default MyPlanForm;
