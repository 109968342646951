//Get
const PRODUCT_DATA = {
    PRODUCT_DATA_LOAD: "PRODUCT_DATA_LOAD",
    PRODUCT_DATA_SUCCESS: "PRODUCT_DATA_SUCCESS",
    PRODUCT_DATA_FAIL: "PRODUCT_DATA_FAIL",
};
const PRODUCT_GET_API = "/product/mainTable/query";

//get row count
const PRODUCT_COUNT = {
    PRODUCT_COUNT_LOAD: "PRODUCT_COUNT_LOAD",
    PRODUCT_COUNT_SUCCESS: "PRODUCT_COUNT_SUCCESS",
    PRODUCT_COUNT_FAIL: "PRODUCT_COUNT_FAIL",
}
const PRODUCT_COUNT_API = "/product/mainTable/rowCount"

//Get
const ACCESSORY_DATA = {
    ACCESSORY_DATA_LOAD: "ACCESSORY_DATA_LOAD",
    ACCESSORY_DATA_SUCCESS: "ACCESSORY_DATA_SUCCESS",
    ACCESSORY_DATA_FAIL: "ACCESSORY_DATA_FAIL",
};
const ACCESSORY_GET_API = "/product/mainTable/accessory/query"; 

//get row count
const ACCESSORY_COUNT = {
    ACCESSORY_COUNT_LOAD: "ACCESSORY_COUNT_LOAD",
    ACCESSORY_COUNT_SUCCESS: "ACCESSORY_COUNT_SUCCESS",
    ACCESSORY_COUNT_FAIL: "ACCESSORY_COUNT_FAIL",
}
const ACCESSORY_COUNT_API = "/product/mainTable/accessory/rowCount"


// const PRODUCT_DATA = {
//     PRODUCT_DATA_LOAD:'PRODUCT_DATA_LOAD', 
//     PRODUCT_DATA_SUCCESS:'PRODUCT_DATA_SUCCESS', 
//     PRODUCT_DATA_FAIL:'PRODUCT_DATA_FAIL'
// }

// const PRODUCT_GET_API = '/product/mainTable/?p=2'

const PRODUCT_ADD_DATA = {
    PRODUCT_ADD_DATA_LOAD:'PRODUCT_ADD_DATA_LOAD',
    PRODUCT_ADD_DATA_SUCCESS:'PRODUCT_ADD_DATA_SUCCESS', 
    PRODUCT_ADD_DATA_FAIL:'PRODUCT_ADD_DATA_FAIL'
}

const PRODUCT_POST_API = '/product'


const PRODUCT_UPDATE_DATA = {
    PRODUCT_UPDATE_DATA_LOAD:'PRODUCT_UPDATE_DATA_LOAD',
    PRODUCT_UPDATE_DATA_SUCCESS:'PRODUCT_UPDATE_DATA_SUCCESS', 
    PRODUCT_UPDATE_DATA_FAIL:'PRODUCT_UPDATE_DATA_FAIL'
}

const PRODUCT_PUT_API = '/product/product/'


const PRODUCT_GET_DATA = {
    PRODUCT_GET_DATA_LOAD:'PRODUCT_GET_DATA_LOAD',
    PRODUCT_GET_DATA_SUCCESS:'PRODUCT_GET_DATA_SUCCESS', 
    PRODUCT_GET_DATA_FAIL:'PRODUCT_GET_DATA_FAIL'
}

const PRODUCT_GET_DETAIL_API = '/product/'

//Delete
const PRODUCT_DELETE_DATA = {
    PRODUCT_DELETE_DATA_LOAD:'PRODUCT_DELETE_DATA_LOAD',
    PRODUCT_DELETE_DATA_SUCCESS:'PRODUCT_DELETE_DATA_SUCCESS', 
    PRODUCT_DELETE_DATA_FAIL:'PRODUCT_DELETE_DATA_FAIL'
}

const PRODUCT_DELETE_API = '/product'

//Import 
const PRODUCT_IMPORT_DATA = {
    PRODUCT_IMPORT_DATA_LOAD:'PRODUCT_IMPORT_DATA_LOAD',
    PRODUCT_IMPORT_DATA_SUCCESS:'PRODUCT_IMPORT_DATA_SUCCESS', 
    PRODUCT_IMPORT_DATA_FAIL:'PRODUCT_IMPORT_DATA_FAIL'
}

const PRODUCT_IMPORT_API = '/product/import/excel/'

//Import Product
const ACCESSORY_IMPORT_DATA = {
    ACCESSORY_IMPORT_DATA_LOAD:'ACCESSORY_IMPORT_DATA_LOAD',
    ACCESSORY_IMPORT_DATA_SUCCESS:'ACCESSORY_IMPORT_DATA_SUCCESS', 
    ACCESSORY_IMPORT_DATA_FAIL:'ACCESSORY_IMPORT_DATA_FAIL'
}

const ACCESSORY_IMPORT_API = '/product/import/excel/'

//Category Product
const CATEGORY_PRODUCT_DATA = {
    CATEGORY_PRODUCT_DATA_LOAD: "CATEGORY_PRODUCT_DATA_LOAD",
    CATEGORY_PRODUCT_DATA_SUCCESS: "CATEGORY_PRODUCT_DATA_SUCCESS",
    CATEGORY_PRODUCT_DATA_FAIL: "CATEGORY_PRODUCT_DATA_FAIL",
};
const CATEGORY_PRODUCT_GET_API = "/product/relation/category_prd/";

// Create / Add / Post
const CATEGORY_PRODUCT_ADD_DATA = {
    CATEGORY_PRODUCT_ADD_DATA_LOAD: "CATEGORY_PRODUCT_ADD_DATA_LOAD",
    CATEGORY_PRODUCT_ADD_DATA_SUCCESS: "CATEGORY_PRODUCT_ADD_DATA_SUCCESS",
    CATEGORY_PRODUCT_ADD_DATA_FAIL: "CATEGORY_PRODUCT_ADD_DATA_FAIL",
};

const CATEGORY_PRODUCT_POST_API = "/product/relation/category_prd/create";

// Delete
const CATEGORY_PRODUCT_DELETE_DATA = {
    CATEGORY_PRODUCT_DELETE_DATA_LOAD: "CATEGORY_PRODUCT_DELETE_DATA_LOAD",
    CATEGORY_PRODUCT_DELETE_DATA_SUCCESS: "CATEGORY_PRODUCT_DELETE_DATA_SUCCESS",
    CATEGORY_PRODUCT_DELETE_DATA_FAIL: "CATEGORY_PRODUCT_DELETE_DATA_FAIL",
};
  
const CATEGORY_PRODUCT_DELETE_API = "/product/relation/category_prd/delete";

// Get Product Type
const PRODUCT_TYPE_DATA = {
    PRODUCT_TYPE_DATA_LOAD: "PRODUCT_TYPE_DATA_LOAD",
    PRODUCT_TYPE_DATA_SUCCESS: "PRODUCT_TYPE_DATA_SUCCESS",
    PRODUCT_TYPE_DATA_FAIL: "PRODUCT_TYPE_DATA_FAIL",
};
const PRODUCT_TYPE_GET_API = "/product/pType";

// Get Account Type
const ACCOUNT_TYPE_DATA = {
    ACCOUNT_TYPE_DATA_LOAD: "ACCOUNT_TYPE_DATA_LOAD",
    ACCOUNT_TYPE_DATA_SUCCESS: "ACCOUNT_TYPE_DATA_SUCCESS",
    ACCOUNT_TYPE_DATA_FAIL: "ACCOUNT_TYPE_DATA_FAIL",
};
const ACCOUNT_TYPE_GET_API = "/product/aType";

// Get Category
const CATEGORY_DATA = {
    CATEGORY_DATA_LOAD: "CATEGORY_DATA_LOAD",
    CATEGORY_DATA_SUCCESS: "CATEGORY_DATA_SUCCESS",
    CATEGORY_DATA_FAIL: "CATEGORY_DATA_FAIL",
};
const CATEGORY_GET_API = "/product/cType";

// Get Sub Category Type
const SUB_CATEGORY_DATA = {
    SUB_CATEGORY_DATA_LOAD: "SUB_CATEGORY_DATA_LOAD",
    SUB_CATEGORY_DATA_SUCCESS: "SUB_CATEGORY_DATA_SUCCESS",
    SUB_CATEGORY_DATA_FAIL: "SUB_CATEGORY_DATA_FAIL",
};
const SUB_CATEGORY_GET_API = "/product/scType";

export { PRODUCT_DATA, PRODUCT_GET_API, PRODUCT_COUNT, PRODUCT_COUNT_API, PRODUCT_IMPORT_DATA, PRODUCT_IMPORT_API, PRODUCT_ADD_DATA, PRODUCT_POST_API, PRODUCT_UPDATE_DATA, PRODUCT_PUT_API, PRODUCT_DELETE_DATA, PRODUCT_DELETE_API, PRODUCT_GET_DATA, PRODUCT_GET_DETAIL_API, CATEGORY_PRODUCT_DATA, CATEGORY_PRODUCT_GET_API, CATEGORY_PRODUCT_ADD_DATA, CATEGORY_PRODUCT_POST_API, CATEGORY_PRODUCT_DELETE_DATA, CATEGORY_PRODUCT_DELETE_API, PRODUCT_TYPE_DATA, PRODUCT_TYPE_GET_API, ACCOUNT_TYPE_DATA, ACCOUNT_TYPE_GET_API, CATEGORY_DATA, CATEGORY_GET_API, SUB_CATEGORY_DATA, SUB_CATEGORY_GET_API, ACCESSORY_COUNT, ACCESSORY_COUNT_API, ACCESSORY_DATA, ACCESSORY_GET_API, ACCESSORY_IMPORT_DATA, ACCESSORY_IMPORT_API  }

