import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Col, Row, Card } from "react-bootstrap";
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../../shared/components/Breadcrumbs";
import { loadAccountTypeDataRequest, loadAccessoryDataRequest, loadProductDeleteDataRequest, loadProductImportDataRequest, loadAccessoryRowCount, loadProductTypeDataRequest, loadSubCategoryDataRequest } from "../redux/Actions";
import { CANCEL, CREATENEW, IMPORT, SAVE } from "../../../../shared/constants/Constants";
import DefaultButton from "../../../../shared/components/DefaultButton";
import ProductAdd from "./ProductAdd";
import Modalpopup from "../../../../shared/components/Modalpopup";
import ProductEdit from "./ProductEdit";
import DeleteModal from "../../../../shared/components/DeleteModal";
import ServerSideListView from "../../../../shared/components/listview/ServerSideListView";
import TablePaging from "../../../../shared/components/TablePaging";
import { addTableFilter } from "../../../table_filters_redux/Actions";
import dropdownFilterFunction from "../../../../shared/components/listview/dropdownFilterFunction";
import { loadPTDataRequest } from "../../productTables/redux/Actions";
import Permissions from "../../../../shared/components/Permissions";
import DatePicker from "react-datepicker"

const Accessory = () => {

  let dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation(); 

  let { accessories, rowCount, isImportFile, pTypes, aTypes, subcategories } = useSelector((state) => state.products);

  const [showImportModal, setShowImportModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  let [selectedRow, setSelectedRow] = useState("");
  const [shouldReload, setShouldReload] = useState(true);
  const [showModal, setShowModal] = useState(false);

  //Accessory and product
  const [accessoryData, setAccessoryData] = useState([]);

  let [query] = useSearchParams();
  const handleDelete = () => {
      dispatch(loadProductDeleteDataRequest({ id: [selectedRow] })) 
      setShouldReload(true)
      setShowDeleteModal(false);
  }

  //Import 
  const [pVal, setPVal] = useState('accessory');
  const [csvFile, setCSVFile] = useState(null);
  const [eDate, setEDate] = useState();
  const [fileError, setFileError] = useState(false);
  const [url, setURL] = useState("https://wvdb-sharing.s3.amazonaws.com/New+Sku+Commissions+List+Testing+(1).xlsx");
  const handleType = (val) => { 
      setPVal(val);
      if (val === "accessory") {
        setURL("https://wvdb-sharing.s3.amazonaws.com/New+Sku+Commissions+List+Testing+(1).xlsx");
      } else {
        setURL("https://wvdb-sharing.s3.amazonaws.com/Product+import+file+testing.xlsx");
      }
  }

  const handleAddImport = (e) => {
    e.preventDefault();
    if (csvFile !== null) {
      let formData = new FormData();
      formData.append('csv_file', csvFile);
      if (pVal === "accessory") {
        formData.append('effective_date', eDate);
      }
      
      let request = {
          data: formData
      }
      setShowImportModal(false)
      dispatch(loadProductImportDataRequest(request, '/product/import/excel/' + pVal));
    } else {
        setFileError(true);
    }
  }

  let exportTitle = "Products"
  let tableFilterKey = useMemo(() => location.pathname + "/" + exportTitle, [location]);
  let { tableFilters } = useSelector((state) => state.tableFilter)

  let filter = {
      // "product_type_name": {
      //     filterType: "text",
      //     type: "inArray",
      //     filter: "Accessory"
      // }
  };
  if (query.get("filter") && query.get('filter') !== "none") {
      filter = {
          ...filter,
          ...JSON.parse(query.getAll('filter'))
      }
  }
  // if (defaultFilter != null) {
  //   filter = {
  //     ...filter,
  //     ...JSON.parse(defaultFilter)
  //   }
  // }
  for (let field in filter){
      if(field == 'is_commisionable') {
          filter[field].filter = filter[field].filter
      } else {
          filter[field].filter = JSON.stringify([filter[field].filter])
      }
  }
  
  let [filterAndPage, setFilterAndPage] = useState(
      tableFilters[tableFilterKey] && !query.get("filter") ?
          tableFilters[tableFilterKey]
          :
          {
              filter: JSON.stringify(filter),
              page: 1,
              sort: 'null'
          }
  )

  useEffect(() => {
      dispatch(loadAccessoryRowCount(JSON.parse(filterAndPage.filter)));
      dispatch(loadPTDataRequest()); 
      dispatch(loadProductTypeDataRequest());
      dispatch(loadAccountTypeDataRequest());
      dispatch(loadSubCategoryDataRequest());
  }, [dispatch])

  const filterCallback = useCallback((newFilters) => {
      if (JSON.stringify(newFilters.filter) != filterAndPage.filter || JSON.stringify(newFilters.sort) != filterAndPage.sort) {
          let newFilterAndPage = { filter: JSON.stringify(newFilters.filter), page: 1, sort: JSON.stringify(newFilters.sort) }
          setFilterAndPage(newFilterAndPage);
          dispatch(loadAccessoryRowCount(JSON.parse(newFilterAndPage.filter)));
      }
  }, [filterAndPage]);
  useEffect(() => {
      dispatch(addTableFilter(tableFilterKey, filterAndPage))
      dispatch(loadAccessoryDataRequest(JSON.parse(filterAndPage.filter), filterAndPage.page, JSON.parse(filterAndPage.sort)));
  }, [filterAndPage])  

  useEffect(() => {
    dispatch(loadAccessoryRowCount(JSON.parse(filterAndPage.filter)));
    dispatch(loadAccessoryDataRequest(JSON.parse(filterAndPage.filter), filterAndPage.page, JSON.parse(filterAndPage.sort)));
  }, [isImportFile])

  useEffect(() => {
    if (accessories) {
      setAccessoryData([...accessories])
    }
  }, [accessories])

  const [productTypeData, setProductTypeData] = useState([]);
  useEffect(() => {
    if (pTypes) {
      setProductTypeData([...pTypes].filter(
        (productType) => {
            return (productType.name == "Accessory")
        }
      )) 
    }
  }, [pTypes])

  let pTypeOptions = useMemo(() => productTypeData.map((ptype) => ({ value: ptype.name, label: ptype.name })), [productTypeData])
  let pTypeFunc = dropdownFilterFunction(pTypeOptions)

  let aTypeOptions = useMemo(() => aTypes.map((atype) => ({ value: atype.name, label: atype.name })), [aTypes])
  let aTypeFunc = dropdownFilterFunction(aTypeOptions)

  let subcategoryOptions = useMemo(() => subcategories.map((subcategory) => ({ value: subcategory.name, label: subcategory.name })), [subcategories])
  let subcategoryFunc = dropdownFilterFunction(subcategoryOptions)
  

  let emp_no = JSON.parse(localStorage.getItem("emp_no"));
  let module_name = "Products";
  let permission = Permissions(emp_no, module_name);
  
  let actionsPermission = !(permission.write_permission || permission.delete_permission);

  let columns = [
    {
      display_name: "id",
      field: "id",
      type: "number",
      hide: true
    },
    {
      display_name: "Product Name",
      field: "name",
      type: "text",
    },
    {
      display_name: "Description",
      field: "description",
      type: "text",
    },
    {
      display_name: "Product Type",
      field: "product_type_name",
      type: "dropdown",
      dropdownOptions: productTypeData.map(pType => pType.name),
      dropdownFunction: pTypeFunc
    },
    // {
    //   display_name: "Account Type",
    //   field: "account_type_name",
    //   type: "dropdown",
    //   dropdownOptions: aTypes.map(aType => aType.name),
    //   dropdownFunction: aTypeFunc
    // },
    {
      display_name: "Sub Category Type",
      field: "sub_category_name",
      type: "dropdown",
      dropdownOptions: subcategories.map(subcategory => subcategory.name),
      dropdownFunction: subcategoryFunc
    },
    {
      display_name: "Code",
      field: "code",
      type: "text",
    },
    {
      display_name: "Start Date",
      field: "start_date",
      type: "date",
    },
    {
      display_name: "End Date",
      field: "end_date",
      type: "date",
    },
    {
      display_name: "MSRP",
      field: "msrp",
      type: "text",
    },
    {
      display_name: "Commission",
      field: "commission",
      type: "text",
    },
    {
      display_name: "Non Commissionable",
      field: "non_commissionable",
      type: "text",
    },
    {
      display_name: 'Active',
      field: 'active',
      type: 'bool',
      icon: "checkbox",
      width: 100,
    },
    {
      display_name: "",
      field: 'actions',
      type: 'actions',
      hide: actionsPermission
    },
  ];

  let [clickEditFunc, setClickEditFunc] = useState(null);
  let [clickDeleteFunc, setClickDeleteFunc] = useState(null);
  useEffect(()=>{
      if (permission.write_permission){
          setClickEditFunc(
              () => (row)=>{
              setSelectedRow(row);
              setShowEditModal(true);
          })
      }
      if (permission.delete_permission){
          setClickDeleteFunc(
              () => (id)=>{
              setSelectedRow(id);
              setShowDeleteModal(true);
          })
      }
  },[permission])

  const dayjs = require('dayjs')

  return (
    <>
      <div>
        <Row>
          <Col className='gx-0'>
          </Col>
          <Col xs="auto" className="gx-0">
            {permission.create_permission?(<>
              <DefaultButton
                buttonClass={"default-btn-color me-1"}
                buttonTitle={IMPORT} 
                actionBlock={() => { setShowImportModal(true) }}
              />
              <DefaultButton
                buttonClass={"default-btn-color"}
                buttonTitle={CREATENEW}
                actionBlock={() => setShowCreateModal(true)}
              />
            </>):(<></>)}
          </Col>
        </Row>
        <Row>
          <Card>
            <Card.Body>
              <ServerSideListView
                  exportTitle="Products"
                  exportUrl='/product/mainTable/accessory/export'
                  columnNames={columns}
                  rowData={accessoryData}
                  shouldSetNextPrevIds
                  activeEmployeeFilter
                  filterCallback={filterCallback}
                  filterString={filterAndPage.filter}
                  sort={filterAndPage.sort}
                  tableHeight = '70vh'
                  clickEdit={clickEditFunc}
                  clickDelete={clickDeleteFunc}
              />
            </Card.Body>
            <Row>
              <Col></Col>
              <Col sm="auto">
              </Col>
              <Col sm={"auto"}>
                  <TablePaging page={filterAndPage.page} setPage={(num) => { setFilterAndPage({ ...filterAndPage, page: num }) }} total={rowCount} />
              </Col>
          </Row>
          </Card>
        </Row>
      </div>
      <Modalpopup
          show={showCreateModal}
          handleClose={() => setShowCreateModal(false)}
          actionTitle={"Create Accessory"}
      >
          <ProductAdd
            handleClose={(reload) => {
              setShowCreateModal(false);
              if (reload) {
                setTimeout(() => {
                  window.location.reload()
                }, 3000);
              }
            }}
            productType="accessory"
          />
      </Modalpopup>
      <Modalpopup
          show={showEditModal}
          handleClose={() => { setShowEditModal(false) }}
          actionTitle={"Edit Accessory"}
      >
          <ProductEdit
              pData={selectedRow}
              handleClose={(reload) => {
                setShowEditModal(false)
                if (reload) {
                  setTimeout(() => {
                    window.location.reload()
                  }, 3000);
                }
              }}
              productType="accessory"
          />
      </Modalpopup>
      <DeleteModal
          handleClose={() => { setShowDeleteModal(false) }}
          handleDelete={handleDelete}
          show={showDeleteModal}
      >
          <div>
              Delete Accessory?
          </div>
      </DeleteModal>
      <Modalpopup
          show={showImportModal}
          handleClose={() => setShowImportModal(false)}
          actionTitle={"Import Accessory"}
          sampleFile="yes"
          sampleFileURL={url}
      >
        <form className="row g-4">
            <div className="col-md-8" style={{ display:"none" }}> 
              <div className="form-check">
                  <input type="radio" className="form-check-input" id="radio1" name="product_type" value="accessory" checked={pVal === "accessory" &&  ("checked")} onClick={() => handleType("accessory")}   />Accessory
                  <label className="form-check-label" for="radio1"></label>
              </div>
            </div>
            {/* <div className="col-md-5">
              <div className="form-check">
                  <input type="radio" className="form-check-input" id="radio1" name="product_type" value="product" checked={pVal === "product" &&  ("checked")} onClick={() => handleType("product")}  />Product
                  <label className="form-check-label" for="radio1"></label>
              </div>
            </div> */}
            {pVal === "accessory" && (
              <div className="col-md-6 datepicker">
                <label htmlFor="import_company" className="form-label">
                  Effective Date <span className="error-text">*</span>
                </label>
                <DatePicker
                    selected={eDate? new Date(eDate):null}
                    onChange={val => {
                      let fullDate = dayjs(val).format('MM/DD/YYYY');                    
                      setEDate(fullDate); 
                    }}
                    placeholderText="Effective Date"
                    autoComplete="off"
                    dateFormat="yyyy-MM-dd"
                />
              </div>
            )}
            <div className="col-md-6">
                <label htmlFor="import_company" className="form-label">
                    Excel File <span className="error-text">*</span>
                </label>
                <input
                    type="file"
                    className="form-control"
                    id="import_company"
                    name="import_company"
                    onChange={(event) => { setCSVFile(event.target.files[0]) }}
                />
                <span className="error-text">
                    {fileError ? (
                        <div>Please upload file</div>
                    ) : null}
                </span>
            </div>
            <div className="col-md-12">
                <DefaultButton
                    buttonClass={"btn btn-secondary me-2"}
                    buttonTitle={CANCEL} 
                    actionBlock={() => setShowImportModal(false)} 
                />
                <DefaultButton
                    buttonClass={"btn btn-secondary default-btn-color"}
                    buttonTitle={"Upload"}
                    actionBlock={handleAddImport}
                />
            </div>
        </form>
      </Modalpopup>
    </>
  );
};

export default Accessory;
